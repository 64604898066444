import { ISubscriptionServiceModel } from '@/store/contracts/subscriptions/addService';
import { SubscriptionServiceType } from '@/store/contracts/subscriptions/subscriptionServiceType';
import { VisionFeaturesFlag } from '@/store/contracts/subscriptions/visionFeaturesFlag';
import { ValidationModel } from '@/validation';
import { conditionalRules, getValueRequiredRule, numberRequiredRule } from '@/validation/common/rules';
import { isAfter, isBefore } from '@/validation/common/dateRules';

export enum SubscriptionServiceFieldName {
  DailyQuota = 'dailyQuota',
  MonthlyQuota = 'monthlyQuota',
  ContractStartDate = 'contractStartDate',
  ContractEndDate = 'contractEndDate',
  ContractQuota = 'contractQuota',
  ServiceType = 'serviceType',
  FeaturesFlag = 'featuresFlag'
}

class SubscriptionServiceValidationModel extends ValidationModel<ISubscriptionServiceModel> {
  constructor () {
    super();

    this.setField(SubscriptionServiceFieldName.ContractQuota, {
      getter: (model) => model?.contractQuota ?? null,
      rules: [
        numberRequiredRule('Contract Quota is required.')
      ]
    });

    this.setField(SubscriptionServiceFieldName.ContractStartDate, {
      getter: (model) => model?.contractStartDate ?? null,
      rules: [
        getValueRequiredRule((value: Date | null) => value !== null)('Contract Start Date is required.'),
        isBefore((model) => model?.contractEndDate ?? null, 'Should be before Contract End Date.')
      ]
    });

    this.setField(SubscriptionServiceFieldName.ContractEndDate, {
      getter: (model) => model?.contractEndDate ?? null,
      rules: [
        getValueRequiredRule((value: Date | null) => value !== null)('Contract End Date is required.'),
        isAfter((model) => model?.contractStartDate ?? null, 'Should be after Contract Start Date.')
      ]
    });

    this.setField(SubscriptionServiceFieldName.ServiceType, {
      getter: (model) => model?.serviceType ?? null,
      rules: [
        getValueRequiredRule((v) => v !== null && v !== SubscriptionServiceType.None)('Service Type is required.'),
        async (context) => {
          const existingServiceTypes = context.model?.createModel?.services.filter(s => s.index === null || s.index !== context.model?.index).map((s) => s.serviceType) ?? [];
          const newServiceType = context.model?.serviceType ?? SubscriptionServiceType.None;
          return existingServiceTypes.none((s) => s === newServiceType) || 'Can not have multiple services of the same type';
        }
      ]
    });

    this.setField(SubscriptionServiceFieldName.FeaturesFlag, {
      getter: (model) => model?.featuresFlag ?? null,
      rules: [
        ...conditionalRules<ISubscriptionServiceModel, number | null>(
          (ctx) => ctx.model?.serviceType === SubscriptionServiceType.Vision,
          [
            getValueRequiredRule((f) => f !== null && f !== VisionFeaturesFlag.None)('Features are required.')
          ])
      ]
    });
  }

  public get modelName (): string {
    return 'SubscriptionService';
  }
}

export default new SubscriptionServiceValidationModel();
