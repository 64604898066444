export function twoDigitDates (value: number): string {
  return value < 10 ? `0${value}` : value.toString();
}

export function shortDateFormat (date: Date | null | undefined): string | null {
  return !date ? null : `${twoDigitDates(date.getUTCMonth() + 1)}/${twoDigitDates(date.getUTCDate())}/${date.getUTCFullYear()}`;
}

export function setStart (date: Date | null | undefined): void {
  if (!date) {
    return;
  }

  date.setUTCHours(0);
  date.setUTCMinutes(0);
  date.setUTCSeconds(0);
  date.setMilliseconds(0);
}

export function setEnd (date: Date | null | undefined): void {
  if (!date) {
    return;
  }

  date.setUTCHours(23);
  date.setUTCMinutes(59);
  date.setUTCSeconds(59);
  date.setMilliseconds(999);
}
