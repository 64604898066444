import { IUpdateLabelModel } from '@/store/contracts/subscriptions/subscription';
import { ValidationModel } from '@/validation';
import { stringRequiredRule } from '@/validation/common/rules';

export enum UpdateLabelFieldName {
  Label = 'label'
}

class UpdateSubscriptionLabelValidationModel extends ValidationModel<IUpdateLabelModel> {
  constructor () {
    super();

    this.setField(UpdateLabelFieldName.Label, {
      getter: (model) => model?.label ?? null,
      rules: [
        stringRequiredRule('Label is required')
      ]
    });
  }

  get modelName (): string {
    return 'UpdateSubscriptionLabelValidationModel';
  }
}

export default new UpdateSubscriptionLabelValidationModel();
