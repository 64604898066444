import { ISubscriptionServiceModel } from '@/store/contracts/subscriptions/addService';
import { ISubscriptionCreateModel } from '@/store/contracts/subscriptions/create';
import { ISubscriptionService } from '@/store/contracts/subscriptions/subscriptions';
import { SubscriptionServiceType } from '@/store/contracts/subscriptions/subscriptionServiceType';
import { impl } from '@/utils/impl';
import { DeepReadonly } from 'vue';
import { IRemainingContractUnits } from './subscriptionListingModel';

export interface IServerSubscriptionService {
  ContractStartDate: Date | null;
  ContractEndDate: Date | null;
  ContractQuota: number | null;
  MonthlyQuota: number | null;
  DailyQuota: number | null;
  ServiceType: SubscriptionServiceType;
  FeaturesFlag: number | null;
  TanglesApiKey: string | null;
}

export interface IServerUpdateServiceModel {
  Services: IServerSubscriptionService[];
}

export function cleanServerQuota (quota: number | null): number | null {
  return quota === null ? null : Math.ceil(quota);
}

export function toServer (app: ISubscriptionService): IServerSubscriptionService {
  return impl<IServerSubscriptionService>({
    ContractStartDate: app.contractStartDate,
    ContractEndDate: app.contractEndDate,
    ContractQuota: cleanServerQuota(app.contractQuota),
    MonthlyQuota: cleanServerQuota(app.monthlyQuota),
    DailyQuota: cleanServerQuota(app.dailyQuota),
    ServiceType: app.serviceType,
    FeaturesFlag: app.featuresFlag ?? null,
    TanglesApiKey: app.tanglesApiKey ?? null
  });
}

export function toApp (server: IServerSubscriptionService, index: number, remainingContractUnits: IRemainingContractUnits | null): ISubscriptionService {
  return impl<ISubscriptionService>({
    contractStartDate: server.ContractStartDate === null ? null : new Date(server.ContractStartDate),
    contractEndDate: server.ContractEndDate === null ? null : new Date(server.ContractEndDate),
    remainingContractUnits: remainingContractUnits ? setRemainingContractUnits(server.ServiceType, remainingContractUnits) : server.ContractQuota,
    contractQuota: server.ContractQuota,
    monthlyQuota: server.MonthlyQuota,
    dailyQuota: server.DailyQuota,
    serviceType: server.ServiceType,
    featuresFlag: server.FeaturesFlag,
    tanglesApiKey: server.TanglesApiKey,
    createModel: null,
    index: index
  });
}

export function fromAdd (add: ISubscriptionServiceModel | DeepReadonly<ISubscriptionServiceModel>): ISubscriptionService {
  return impl<ISubscriptionService>({
    contractStartDate: add.contractStartDate,
    contractEndDate: add.contractEndDate,
    remainingContractUnits: null, // Remaining contract units is not present on Add Service Dialog.
    contractQuota: add.contractQuota,
    monthlyQuota: add.monthlyQuota,
    dailyQuota: add.dailyQuota,
    serviceType: add.serviceType ?? SubscriptionServiceType.None,
    featuresFlag: add.featuresFlag,
    tanglesApiKey: add.tanglesApiKey,
    createModel: add.createModel as ISubscriptionCreateModel,
    index: add.index
  });
}

export function setRemainingContractUnits (serviceType: SubscriptionServiceType, remainingContractUnits: IRemainingContractUnits): number {
  switch (serviceType) {
    case SubscriptionServiceType.Transcription:
      return remainingContractUnits.Transcription;
    case SubscriptionServiceType.Vision:
      return remainingContractUnits.Vision;
    case SubscriptionServiceType.Translation:
      return remainingContractUnits.Translation;
    case SubscriptionServiceType.Profile:
      return remainingContractUnits.Profile;
    case SubscriptionServiceType.None:
      throw Error('SubscriptionServiceType.None is not a valid service type');
  }
}
