<template>
  <q-dialog
    v-model="isOpen"
    persistent>
    <q-card class="o-diagnostics">
      <q-card-section class="m-dialog-header">
        <div class="m-dialog-header__title">Diagnostics</div>
      </q-card-section>
      <q-card-section class="m-dialog__content">
        <q-btn
          class="o-diagnostics__action -refreshCachedSubscriptions"
          label="Refresh Cached Subscriptions"
          color="pl-navy-900"
          :loading="isRefreshingSubscriptions"
          @click="refreshCachedSubscriptions">
          <q-tooltip anchor="center right" self="center left">
            <div class="o-diagnostics__tooltip">
              Refreshes all cached subscription information so that subscriptions will be reloaded from the database and re-cached.
              <br>
              Used after an update to add/remove/change subscription properties to ensure that cached subscriptions are up to date with changes
            </div>
          </q-tooltip>
          &nbsp;&nbsp;
        </q-btn>
        <q-icon
            class="o-diagnostics__icon"
            :name="refreshSubscriptionsSuccessIcon[0]"
            :color="refreshSubscriptionsSuccessIcon[1]"
            size="x-large"/>
      </q-card-section>
      <q-card-section class="m-dialog__actions row justify-end items-center">
        <q-btn
          class="o-diagnostics__action -close"
          label="Close"
          outline
          color="pl-navy-900"
          @click="close"/>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { computed, defineComponent, Ref, ref, watch } from 'vue';
import { useStore as useSubscriptionsStore } from '@/store/subscriptions/subscriptions';

export default defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      required: true
    }
  },
  setup (props, { emit }) {
    const subscriptionsStore = useSubscriptionsStore();

    const isOpen = computed({
      get: () => props.modelValue,
      set: (v) => emit('update:modelValue', v)
    });

    watch(() => props.modelValue, reset);

    const isRefreshingSubscriptions = ref(false);
    const refreshSubscriptionsSuccess: Ref<boolean | null> = ref(null);
    const refreshSubscriptionsSuccessIcon = computed(() => {
      if (refreshSubscriptionsSuccess.value === null) {
        return [null, null];
      }

      return refreshSubscriptionsSuccess.value ? ['mdi-check-circle', 'green'] : ['mdi-alpha-x-circle green', 'red'];
    });

    async function refreshCachedSubscriptions () {
      isRefreshingSubscriptions.value = true;

      refreshSubscriptionsSuccess.value = await subscriptionsStore.refreshCachedSubscriptions();

      isRefreshingSubscriptions.value = false;
    }

    function reset () {
      isRefreshingSubscriptions.value = false;
      refreshSubscriptionsSuccess.value = null;
    }

    function close () {
      isOpen.value = false;
    }

    return {
      isOpen,
      refreshCachedSubscriptions,
      refreshSubscriptionsSuccess,
      refreshSubscriptionsSuccessIcon,
      isRefreshingSubscriptions,
      close
    };
  }
});
</script>

<style lang="scss">
.o-diagnostics {
  margin: 20px;
  border-radius: 15px;
  min-width: 10vw;

  &__field+&__field {
    margin-top: 16px;
  }

  &__action+&__action {
    margin-left: 8px;
  }
}
</style>
