import { ITransactionsFilterModel } from '@/store/contracts/subscriptions/transactions';
import { ValidationModel } from '@/validation';
import { isAfter, isBefore } from '@/validation/common/dateRules';

export enum TransactionsFilterFieldName {
  ServiceType = 'serviceType',
  From = 'from',
  To = 'to'
}

class TransactionsFilterValidationModel extends ValidationModel<ITransactionsFilterModel> {
  constructor () {
    super();

    this.setField(TransactionsFilterFieldName.From, {
      getter: (model) => model?.from ?? null,
      rules: [
        isBefore((model) => model?.to ?? null, 'Should be before "To".')
      ]
    });

    this.setField(TransactionsFilterFieldName.To, {
      getter: (model) => model?.to ?? null,
      rules: [
        isAfter((model) => model?.from ?? null, 'Should be after "From".')
      ]
    });
  }

  public get modelName (): string {
    return 'TransactionFilter';
  }
}

export default new TransactionsFilterValidationModel();
