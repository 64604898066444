import { impl } from '@/utils/impl';
import { inject, provide, reactive, readonly } from 'vue';
import {
  ISubscriptionCreateResponseModel,
  ISubscriptionCreateState,
  ISubscriptionCreateStore,
  TSave,
  TSearchSystems,
  TSetActiveDate,
  TSetExpiresDate,
  TSetLabel,
  TSetMachineId,
  TSetModel,
  TSetNote,
  TSetSearchResults,
  TSetSearchTerm,
  TSetServices,
  TSetSystemInfo
} from '@/store/contracts/subscriptions/create';
import { ApiResponse, chainHandlers, DefaultIStatusHandler, handleErrorStatus, handleErrorStatusResult, IStatusHandler } from '@/store/utils/apiResponseHandling';
import { sendRequest } from '@/store/utils/axiosUtils';
import { Settings } from '@/settings';
import { toServer as createModelToServer } from '@/store/subscriptions/mapping/subscriptionCreateModel';
import { IServerSystemInforSearchResponse, toApp as systemInfoToApp } from '@/store/subscriptions/mapping/systemInfoSearch';
import { store as validationStore } from '@/store/validation';
import validationModel, { SubscriptionCreateFieldName } from '@/validation/subscriptionCreateValidationModel';
import { IIndexable, mapKeys } from '@/utils/indexable';
import { isNullOrWhitespace } from '@/utils/stringUtils';

const SubscriptionCreateStoreKey = Symbol('SubscriptionCreateStore');

function setModel (state: ISubscriptionCreateState): TSetModel {
  return (model) => {
    state.model = model;
  };
}

function setLabel (state: ISubscriptionCreateState): TSetLabel {
  return (label) => {
    state.model.label = label;
  };
}

function setMachineId (state: ISubscriptionCreateState): TSetMachineId {
  return (machineId) => {
    const existingLabel = state.model.label;
    const resultMatch = state.systemSearchResults.find(info => info.machineId === machineId);
    state.systemInfo = resultMatch ?? null;
    state.model.machineId = machineId;
    if (isNullOrWhitespace(existingLabel) ||
      existingLabel === state.systemInfo?.customerName) {
      state.model.label = resultMatch?.customerName ?? null;
    }
  };
}

function setSystemInfo (state: ISubscriptionCreateState): TSetSystemInfo {
  return (systemInfo) => {
    const existingLabel = state.model.label;
    state.systemInfo = systemInfo;
    state.model.machineId = systemInfo?.machineId ?? null;
    if (isNullOrWhitespace(existingLabel) ||
      existingLabel === state.systemInfo?.customerName) {
      state.model.label = systemInfo?.customerName ?? null;
    }
  };
}

function setActiveDate (state: ISubscriptionCreateState): TSetActiveDate {
  return (activeDate) => {
    state.model.activeDate = activeDate;
  };
}

function setExpiresDate (state: ISubscriptionCreateState): TSetExpiresDate {
  return (expiresDate) => {
    state.model.expiresDate = expiresDate;
  };
}

function setNote (state: ISubscriptionCreateState): TSetNote {
  return (note) => {
    state.model.note = note;
  };
}

function setServices (state: ISubscriptionCreateState): TSetServices {
  return (services) => {
    state.model.services = services;
  };
}

function setSearchTerm (state: ISubscriptionCreateState): TSetSearchTerm {
  return (searchTerm) => {
    state.systemSearchTerm = searchTerm;
  };
}

function setSearchResults (state: ISubscriptionCreateState): TSetSearchResults {
  return (searchResults) => {
    state.systemSearchResults = searchResults.sortBy(s => s.customerName);
  };
}

function searchSystems (state: ISubscriptionCreateState): TSearchSystems {
  return async (handler) => {
    await handleErrorStatusResult(await sendRequest<IServerSystemInforSearchResponse>(
      `${Settings.adminApiBaseUrl}/admin/v1/subscription/system/search`,
      'post',
      {
        partialSystemName: state.systemSearchTerm ?? ''
      }
    ), chainHandlers({
      onSuccess: async (r) => setSearchResults(state)(mapKeys(r.Systems.sortBy(s => s.CustomerName).groupBy(s => s.MachineId), (val) => {
        if (val.length > 1) {
          val[0].CustomerName = `${val[0].CustomerName} Et. Al.`;
        }
        return val[0];
      }).map(systemInfoToApp))
    }, handler));
  };
}

const createErrorPropToValidationProp: IIndexable<string> = {
  MachineId: SubscriptionCreateFieldName.MachineId,
  ActiveDate: SubscriptionCreateFieldName.ActiveDate,
  ExpiresDate: SubscriptionCreateFieldName.ExpiresDate,
  Services: SubscriptionCreateFieldName.Services,
  Note: SubscriptionCreateFieldName.Note
};

function save (state: ISubscriptionCreateState): TSave {
  return async () => {
    const valid = await validationStore.doValidation({
      model: validationModel,
      value: state.model
    });
    let response: ApiResponse<ISubscriptionCreateResponseModel> | null = null;
    if (valid) {
      const handler = impl<Partial<IStatusHandler<ISubscriptionCreateResponseModel>>>({
        onError: async (r) => {
          const status = r?.status ?? 500;
          if (status !== 500) {
            const data = r?.data ?? {};
            let unhandled = false;
            Object.keys(data).forEach((errKey) => {
              const vProp = createErrorPropToValidationProp[errKey];
              if (vProp) {
                validationStore.setModelFieldResult(validationModel.modelName, vProp, data[errKey].join('\n'));
              } else {
                unhandled = true;
              }
            });
            if (!unhandled) {
              return;
            }
          }
          await DefaultIStatusHandler.onError(r);
        }
      });
      response = await sendRequest<ISubscriptionCreateResponseModel>(
        `${Settings.adminApiBaseUrl}/admin/v1/subscription/create`,
        'post',
        createModelToServer(state.model)
      );
      await handleErrorStatus(response, handler);
    }

    const responseValid = validationStore.isValid(validationModel);
    return !responseValid ? null : response?.[1]?.data.SubscriptionId;
  };
}

export function createState (): ISubscriptionCreateState {
  validationStore.clear(validationModel);

  return reactive(impl<ISubscriptionCreateState>({
    model: {
      label: null,
      machineId: null,
      activeDate: null,
      expiresDate: null,
      note: null,
      services: []
    },
    systemInfo: null,
    systemSearchResults: [],
    systemSearchTerm: null
  }));
}

export function createForState (state: ISubscriptionCreateState): ISubscriptionCreateStore {
  return impl<ISubscriptionCreateStore>({
    state: readonly(state),
    setModel: setModel(state),
    setLabel: setLabel(state),
    setMachineId: setMachineId(state),
    setSystemInfo: setSystemInfo(state),
    setActiveDate: setActiveDate(state),
    setExpiresDate: setExpiresDate(state),
    setNote: setNote(state),
    setServices: setServices(state),
    setSearchTerm: setSearchTerm(state),
    setSearchResults: setSearchResults(state),
    searchSystems: searchSystems(state),
    save: save(state)
  });
}

export function provideStore (): ISubscriptionCreateStore {
  const state = createState();

  provide(SubscriptionCreateStoreKey, state);

  return createForState(state);
}

export function useStore (): ISubscriptionCreateStore {
  const state = inject<ISubscriptionCreateState>(SubscriptionCreateStoreKey);
  if (state === undefined) {
    throw new Error('Using SubscriptionCreateStore before providing it!');
  }
  return createForState(state);
}
