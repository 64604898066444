<template>
  <apexchart
    type="line"
    :width="chartWidth"
    class="a-transactionChart"
    :options="options"
    :series="series"
    @mounted="onChartMounted"
  > </apexchart>
</template>

<script lang="ts">
import { computed, DeepReadonly, defineComponent, PropType, ref, watch } from 'vue';
import { SubscriptionServiceType } from '@/store/contracts/subscriptions/subscriptionServiceType';
import ApexCharts, { ApexOptions } from 'apexcharts';
import { useQuasar } from 'quasar';
import { useStore as useSubscriptionStore } from '@/store/subscriptions/subscription';
import { ISubscriptionUsage, AggregateUsagePeriodType } from '@/store/contracts/subscriptions/transactions';
import { plusDays } from '@/utils/time';
import { possiblyNull } from '@/utils/nullable';

export default defineComponent({
  props: {
    usages: {
      type: Array as () => (DeepReadonly<ISubscriptionUsage> | ISubscriptionUsage)[],
      required: true
    },
    aggregateType: {
      type: String as PropType<AggregateUsagePeriodType>,
      required: true
    },
    chartTitle: {
      type: String,
      required: true
    },
    serviceTypeDisplay: {
      type: String,
      required: true
    }
  },
  setup: (props) => {
    const $q = useQuasar();
    const subscriptionStore = useSubscriptionStore();
    const perService = computed(() => [...props.usages].groupBy(u => u.serviceType));
    const usageSeries = computed(() => Object.keys(perService.value).map(s => {
      const usages = perService.value[s];
      return {
        name: SubscriptionServiceType[s],
        type: 'area',
        data: usages.map(u => ({
          x: u.recordedDay.getTime(),
          y: u.transactionCount
        }))
      };
    }));
    const quotaSeries = computed(() => Object.keys(perService.value).map(s => {
      const usages = perService.value[s];
      const service = subscriptionStore.state.subscription?.services?.find(svc => svc.serviceType === s);
      const quota = props.aggregateType === AggregateUsagePeriodType.Daily ? service?.dailyQuota ?? 0 : service?.monthlyQuota ?? 0;
      return {
        name: `${SubscriptionServiceType[s]} Quota`,
        type: 'line',
        data: usages.map(q => ({
          x: q.recordedDay.getTime(),
          y: quota
        }))
      };
    }));

    const minTimeDays = computed(() => props.aggregateType === AggregateUsagePeriodType.Daily ? 1 : 30);

    const minTime = computed(() => plusDays(props.usages.map(u => u.recordedDay.getTime()).min(new Date().getTime()), -minTimeDays.value));

    const maxTimeDays = computed(() => props.aggregateType === AggregateUsagePeriodType.Daily ? 30 : 120);

    const maxTime = computed(() => plusDays(props.usages.map(u => u.recordedDay.getTime()).max(new Date().getTime()), maxTimeDays.value));

    const series = computed(() => [...usageSeries.value, ...quotaSeries.value]);

    const optionsDashArray = computed(() => [...usageSeries.value.map(() => 0), ...quotaSeries.value.map(() => 8)]);
    const options = computed<ApexOptions>(() => ({
      chart: {
        id: 'transaction-chart'
      },
      xaxis: {
        type: 'datetime',
        min: minTime.value,
        max: maxTime.value,
        tickPlacement: 'on',
        labels: {
          format: props.aggregateType === AggregateUsagePeriodType.Daily ? 'yyyy-MM-dd' : 'yyyy-MM'
        }
      },
      legend: {
        show: true,
        showForSingleSeries: true
      },
      noData: {
        text: 'No transactions to display'
      },
      stroke: {
        dashArray: optionsDashArray.value
      },
      dataLabels: {
        enabled: true
      },
      title: {
        text: props.chartTitle
      },
      tooltip: {
        x: {
          format: props.aggregateType === AggregateUsagePeriodType.Daily ? 'yyyy-MM-dd' : 'yyyy-MM'
        },
        y: {
          formatter: (val: number) => val + ' ' + props.serviceTypeDisplay
        }
      }
    }));

    const chartWidth = computed(() => $q.screen.sm || $q.screen.xs ? '90%' : 750);

    const chart = ref<ApexCharts | null>(null);
    function onChartMounted (chartContext: ApexCharts) {
      // Have quota series toggled off by default.
      quotaSeries.value.forEach(qs => chartContext.toggleSeries(qs.name));
      // Make sure the chart zooms to the data correctly.
      // It doesn't like to do that, it seems.
      chartContext.zoomX(minTime.value, maxTime.value);

      chart.value = chartContext;
    }

    watch(usageSeries, () => {
      setTimeout(() => possiblyNull(chart.value, onChartMounted), 250);
    });

    return {
      options,
      chartWidth,
      series,
      onChartMounted
    };
  }
});
</script>
