import { ISubscriptionState, ISubscriptionStore, TEnableSubscription, TDisableSubscription, TGenerateNewKey, TLoadSubscription, TSetSubscription, TUpdateActiveExpiresDate, TUpdateLabel, TUpdateNote, TUpdateServices } from '@/store/contracts/subscriptions/subscription';
import { impl } from '@/utils/impl';
import { reactive, readonly, provide, inject } from 'vue';
import { chainHandlers, DefaultIStatusHandler, handleErrorStatus, handleErrorStatusResult, ResponseStatus } from '@/store/utils/apiResponseHandling';
import { sendRequest } from '@/store/utils/axiosUtils';
import { Settings } from '@/settings';
import { store as subscriptionsStore } from '@/store/subscriptions/subscriptions';
import { IServerSubscriptionResponse, subscriptiontoApp, toServerDate } from '@/store/subscriptions/mapping/subscriptionListingModel';
import updateLabelValidationModel from '@/validation/updateSubscriptionLabelValidationModel';
import { store as validationStore } from '@/store/validation';
import updateActiveExpiresDateValidationModel from '@/validation/updateActiveExpiresDateValidationModel';
import { SubscriptionUpdateType } from '../contracts/subscriptions/subscriptionUpdateType';

const SubscriptionStoreKey = Symbol('SubscriptionStore');

const createState = () => reactive(impl<ISubscriptionState>({
  subscription: null
}));

function setSubscription (state: ISubscriptionState): TSetSubscription {
  return (subscription) => {
    state.subscription = subscription;
  };
}

function loadSubscription (state: ISubscriptionState): TLoadSubscription {
  return async (subscriptionId, handler) => {
    await handleErrorStatusResult(await sendRequest<IServerSubscriptionResponse>(
      `${Settings.adminApiBaseUrl}/admin/v1/subscription/load`,
      'post',
      {
        SubscriptionId: subscriptionId
      }
    ), chainHandlers({
      onSuccess: async (s) => setSubscription(state)(subscriptiontoApp(s))
    }, handler));
  };
}

function generateNewKey (state: ISubscriptionState): TGenerateNewKey {
  return async (handler) => {
    if (state.subscription !== null) {
      await handleErrorStatusResult(await sendRequest<ResponseStatus.Success>(
        `${Settings.adminApiBaseUrl}/admin/v1/subscription/newKey`,
        'post',
        {
          SubscriptionId: state.subscription.subscriptionId
        }
      ), chainHandlers({
        onSuccess: async () => loadSubscription(state)(state.subscription!.subscriptionId)
      }, handler));
    }
  };
}

function updateNote (state: ISubscriptionState): TUpdateNote {
  return async (model, handler) => {
    if (state.subscription !== null) {
      await handleErrorStatus(await sendRequest<ResponseStatus.Success>(
        `${Settings.adminApiBaseUrl}/admin/v1/subscription/update`,
        'post',
        {
          $type: SubscriptionUpdateType.UpdateSubscriptionNotes,
          SubscriptionId: state.subscription.subscriptionId,
          Note: model.note
        }
      ), chainHandlers({
        onSuccess: async () => loadSubscription(state)(state.subscription!.subscriptionId),
        onError: async (r) => {
          await DefaultIStatusHandler.onError(r);
        }
      }, handler));
    }
  };
}

function updateLabel (state: ISubscriptionState): TUpdateLabel {
  return async (model, handler) => {
    if (state.subscription !== null) {
      const isValid = await validationStore.doValidation({
        model: updateLabelValidationModel,
        value: model
      });
      if (isValid) {
        await handleErrorStatus(await sendRequest<ResponseStatus.Success>(
          `${Settings.adminApiBaseUrl}/admin/v1/subscription/update`,
          'post',
          {
            $type: SubscriptionUpdateType.UpdateSubscriptionLabel,
            SubscriptionId: state.subscription.subscriptionId,
            Label: model.label
          }
        ), chainHandlers({
          onSuccess: async () => await loadSubscription(state)(state.subscription!.subscriptionId),
          onError: async (r) => {
            await DefaultIStatusHandler.onError(r);
          }
        }, handler));
      }
    }
  };
}

function updateActiveExpiresDate (state: ISubscriptionState): TUpdateActiveExpiresDate {
  return async (model, handler) => {
    if (state.subscription !== null) {
      const isValid = await validationStore.doValidation({
        model: updateActiveExpiresDateValidationModel,
        value: model
      });
      if (isValid) {
        await handleErrorStatus(await sendRequest<ResponseStatus.Success>(
          `${Settings.adminApiBaseUrl}/admin/v1/subscription/update`,
          'post',
          {
            $type: SubscriptionUpdateType.UpdateSubscriptionStatus,
            SubscriptionId: state.subscription.subscriptionId,
            ...toServerDate(model)
          }
        ), chainHandlers({
          onSuccess: async () => await loadSubscription(state)(state.subscription!.subscriptionId),
          onError: async (r) => {
            await DefaultIStatusHandler.onError(r);
          }
        }, handler));
      }
    }
  };
}

function updateServices (state: ISubscriptionState): TUpdateServices {
  return async (model, handler) => {
    if (state.subscription !== null) {
      await handleErrorStatusResult(await sendRequest<ResponseStatus.Success>(
        `${Settings.adminApiBaseUrl}/admin/v1/subscription/update`,
        'post',
        {
          $type: SubscriptionUpdateType.UpdateSubscriptionServices,
          SubscriptionId: state.subscription.subscriptionId,
          Services: model.Services
        }
      ), chainHandlers({
        onSuccess: async () => loadSubscription(state)(state.subscription!.subscriptionId),
        onError: async (r) => {
          await DefaultIStatusHandler.onError(r);
        }
      }, handler));
    }
  };
}

function enableSubscription (state: ISubscriptionState): TEnableSubscription {
  return async (handler) => {
    if (state.subscription !== null) {
      return await subscriptionsStore.enableSubscription(state.subscription.subscriptionId,
        chainHandlers(
          {
            onSuccess: async () => loadSubscription(state)(state.subscription!.subscriptionId)
          }, handler));
    }
    console.warn("Can't enable a subscription when the subscription is null.");
  };
}

function disableSubscription (state: ISubscriptionState): TDisableSubscription {
  return async (handler) => {
    if (state.subscription !== null) {
      return await subscriptionsStore.disableSubscription(state.subscription.subscriptionId,
        chainHandlers(
          {
            onSuccess: async () => loadSubscription(state)(state.subscription!.subscriptionId)
          }, handler));
    }
    console.warn("Can't disable a subscription when the subscription is null.");
  };
}

function isEnabled (state: ISubscriptionState): () => boolean {
  return () => {
    if (state.subscription === null) {
      return false;
    }

    return subscriptionsStore.isEnabled(state.subscription);
  };
}

const createForState = (state: ISubscriptionState) => impl<ISubscriptionStore>({
  state: readonly(state),
  setSubscription: setSubscription(state),
  loadSubscription: loadSubscription(state),
  enableSubscription: enableSubscription(state),
  disableSubscription: disableSubscription(state),
  generateNewKey: generateNewKey(state),
  updateNote: updateNote(state),
  updateServices: updateServices(state),
  updateLabel: updateLabel(state),
  updateActiveExpiresDate: updateActiveExpiresDate(state),
  get isEnabled (): boolean {
    return isEnabled(state)();
  }
});

export function provideStore (): ISubscriptionStore {
  const state = createState();
  provide(SubscriptionStoreKey, state);
  return createForState(state);
}

export function useStore (): ISubscriptionStore {
  const state = inject<ISubscriptionState>(SubscriptionStoreKey);
  if (state === undefined) {
    throw new Error('Using SubscriptionStore before providing it!');
  }
  return createForState(state);
}
