import { SubscriptionServiceType } from '@/store/contracts/subscriptions/subscriptionServiceType';

export function subscriptionServiceTypeGridDisplay (serviceType: SubscriptionServiceType) : string | null {
  switch (serviceType) {
    case SubscriptionServiceType.None:
      return null;
    case SubscriptionServiceType.Vision:
      return 'Lookups';
    case SubscriptionServiceType.Translation:
      return 'Characters';
    case SubscriptionServiceType.Transcription:
      return 'Duration of Audio';
    case SubscriptionServiceType.Profile:
      return 'Searches';
    default:
      return '';
  }
}

export function subscriptionServiceTypeChartDisplay (serviceType: SubscriptionServiceType) : string | null {
  switch (serviceType) {
    case SubscriptionServiceType.None:
      return null;
    case SubscriptionServiceType.Vision:
      return 'Lookups';
    case SubscriptionServiceType.Translation:
      return 'Characters';
    case SubscriptionServiceType.Transcription:
      return 'Hours';
    case SubscriptionServiceType.Profile:
      return 'Searches';
    default:
      return '';
  }
}
