<template>
     <div class="o-subscription-transactions">
        <div class="m-pageHeader">
          <div class="m-pageHeader__title -subscription-transactions text-pl-black text-body2">Subscription Transactions</div>
          <div class="m-pageHeader__actions -subscription-transactions flex justify-end items-center">
            <q-btn
              class="m-pageHeader__action -back"
              icon="mdi-arrow-left"
              outline
              color="pl-navy-900"
              @click="back">
              <q-tooltip> Go Back </q-tooltip>
            </q-btn>
          </div>
        </div>
        <div class="m-pageContent">
          <div class="m-filterControls">
            <q-select
              class="m-filterControls__field -type"
              label="Service"
              v-model="serviceType"
              :options="serviceTypeOptions"
              map-options
              dense
              emit-value
              :error="validationRefs[FieldName.ServiceType].fieldIsError"
              :error-message="validationRefs[FieldName.ServiceType].fieldErrorMessage"
            />
            <DatePicker
              class="m-filterControls__field__date -from"
              :defaultDate="fromDate"
              :isError="validationRefs[FieldName.From].fieldIsError"
              :errorMessage="validationRefs[FieldName.From].fieldErrorMessage"
              label="From"
              :isStart="true"
              :isUpdatingDate="isUpdatingDates"
              @dateChanged="fromDateChanged"
            />
            <DatePicker
              class="m-filterControls__field__date -to"
              :defaultDate="toDate"
              :isError="validationRefs[FieldName.To].fieldIsError"
              :errorMessage="validationRefs[FieldName.To].fieldErrorMessage"
              label="To"
              :isStart="false"
              :isUpdatingDate="isUpdatingDates"
              @dateChanged="toDateChanged"
            />
            <div class="m-filterControls__field m-actions -actions">
              <q-btn
                class="m-actions__action -updateChart"
                flat
                rounded
                icon="mdi-filter-outline"
                dense
                @click="updateChart"
                :loading="updatingChart"
                color="pl-navy-900"
              >
                <q-tooltip>
                  Update the chart.
                </q-tooltip>
              </q-btn>
              <q-btn
                class="m-actions__action -clearFilters"
                flat
                rounded
                :disable="!canClearFilters"
                icon="mdi-filter-remove-outline"
                dense
                @click="clearFilters"
                :loading="updatingChart"
                color="pl-red-300-2"
              >
                <q-tooltip>
                  Clear filters.
                </q-tooltip>
              </q-btn>
            </div>
          </div>
          <div class="m-totalUsages">
            <b>Total Usage:</b> {{totalUsages}} {{units}}
          </div>
          <TransactionChart
            :usages="dailyUsages"
            :aggregateType="AggregateUsagePeriodType.Daily"
            :serviceTypeDisplay="units ?? ''"
            chartTitle="Daily Usage"/>
          <TransactionChart
            :usages="monthlyUsages"
            :aggregateType="AggregateUsagePeriodType.Monthly"
            :serviceTypeDisplay="units ?? ''"
            chartTitle="Monthly Usage"/>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import TransactionChart from '@/components/subscriptions/TransactionChart.vue';
import { provideStore as provideTransactionStore } from '@/store/subscriptions/transactions';
import { provideStore as provideSubscriptionScore } from '@/store/subscriptions/subscription';
import { useRouter } from 'vue-router';
import { goBack } from '@/router/goBack';
import { SubscriptionRoutes } from '@/router/routes/subscriptions';
import {
  SubscriptionServiceTypes,
  SubscriptionServiceType
} from '@/store/contracts/subscriptions/subscriptionServiceType';
import validationModel, {
  TransactionsFilterFieldName as FieldName
} from '@/validation/transactionsFilterValidationModel';
import { AggregateUsagePeriodType, ISubscriptionUsage } from '@/store/contracts/subscriptions/transactions';
import { useFieldsValidation } from '@/components/utils/fieldValidation';
import DatePicker from '@/components/date/DatePicker.vue';
import { delay } from '@/utils/systemUtils';
import { subscriptionServiceTypeChartDisplay } from '@/utils/units';

export default defineComponent({
  components: {
    TransactionChart,
    DatePicker
  },
  props: {
    subscriptionId: {
      type: String,
      required: true
    }
  },
  async setup (props) {
    const transactionStore = provideTransactionStore();
    const subscriptionStore = provideSubscriptionScore();
    const router = useRouter();

    const { validationRefs } = useFieldsValidation(
      validationModel,
      ref(true),
      [FieldName.ServiceType, FieldName.From, FieldName.To]);

    transactionStore.setSubscriptionId(props.subscriptionId);
    watch(() => props.subscriptionId, transactionStore.setSubscriptionId);

    await transactionStore.loadUsages();
    await subscriptionStore.loadSubscription(props.subscriptionId);

    function formatWithCommas (number: number) {
      return number.toLocaleString('en-US');
    }

    const dailyUsages = computed(() => transactionStore.state.dailyUsages as ISubscriptionUsage[]);
    const monthlyUsages = computed(() => transactionStore.state.monthlyUsages as ISubscriptionUsage[]);
    const totalUsages = computed(() => formatWithCommas(transactionStore.state.totalUsages));
    const activeServiceType = ref(SubscriptionServiceType.Vision);
    const units = computed(() => subscriptionServiceTypeChartDisplay(activeServiceType.value));

    const serviceTypeOptions = SubscriptionServiceTypes.filter(t => t !== SubscriptionServiceType.None).map(t => ({
      label: SubscriptionServiceType[t],
      value: t
    }));

    const serviceType = computed({
      get: () => transactionStore.state.filterModel.serviceType ?? SubscriptionServiceType.Vision,
      set: (val) => {
        const changed = val !== serviceType.value;
        transactionStore.setServiceType(val);
        if (changed) {
          setDefaultDates();
        }
      }
    });

    const fromDate = computed({
      get: () => transactionStore.state.filterModel.from,
      set: (val) => transactionStore.setFrom(val)
    });

    const toDate = computed({
      get: () => transactionStore.state.filterModel.to,
      set: (val) => transactionStore.setTo(val)
    });

    const updatingChart = ref(false);
    async function updateChart () {
      updatingChart.value = true;
      await transactionStore.loadUsages();
      activeServiceType.value = transactionStore.state.filterModel.serviceType ?? SubscriptionServiceType.Vision;
      updatingChart.value = false;
    }

    const canClearFilters = computed(() =>
      (transactionStore.state.filterModel.serviceType !== null &&
      transactionStore.state.filterModel.serviceType !== SubscriptionServiceType.Vision) ||
      transactionStore.state.filterModel.from !== null ||
      transactionStore.state.filterModel.to !== null);

    async function clearFilters () {
      transactionStore.setServiceType(null);
      transactionStore.setTo(null);
      transactionStore.setFrom(null);
      await updateChart();
    }

    function fromDateChanged (newDate: Date | null) {
      fromDate.value = newDate;
    }

    function toDateChanged (newDate: Date | null) {
      toDate.value = newDate;
    }

    const isUpdatingDates = ref(false);
    async function setDefaultDates () {
      isUpdatingDates.value = true;
      const service = subscriptionStore.state.subscription?.services.find(s => s.serviceType === serviceType.value);
      fromDate.value = service?.contractStartDate ?? subscriptionStore.state.subscription?.activeDate ?? null;
      toDate.value = service?.contractEndDate ?? subscriptionStore.state.subscription?.expiresDate ?? null;
      await delay(100); // Give the UI a short delay to update before flipping back our update bool
      isUpdatingDates.value = false;
    }

    setDefaultDates();

    return {
      TransactionChart,
      back: goBack(router, { name: SubscriptionRoutes.list }),
      dailyUsages,
      monthlyUsages,
      totalUsages,
      serviceTypeOptions,
      serviceType,
      fromDate,
      toDate,
      FieldName,
      validationRefs,
      updatingChart,
      updateChart,
      clearFilters,
      canClearFilters,
      AggregateUsagePeriodType,
      fromDateChanged,
      toDateChanged,
      isUpdatingDates,
      units
    };
  }
});
</script>

<style lang="scss">
.o-subscription-transactions {
    margin: 20px;

  .m-pageHeader {
    justify-content: space-between;
    display: flex;
    align-items: center;

    &__action {
      margin-left: 8px;
    }
  }

  .m-pageContent {
    margin-top: 16px;
  }

  .m-filterControls {
    display: flex;
    margin-bottom: 16px;

    $this: &;

    &__field {
      flex-grow: 1;

      &.-type {
        padding-top: 20px;
      }

      &__date {
        display: inline-block;

        &.-from {
          padding-left: 20px;
        }
      }
    }

    #{$this}__field+#{$this}__field {
      margin-left: 8px;
    }

    .m-actions {
      display: flex;
      align-items: center;

      &__action {
        margin-bottom: 18px;
      }
    }
  }

  .m-totalUsages {
    font-size: 16px;
    margin-bottom: 18px;
    margin-left: 8px;
  }

  .m-actions__action+.m-actions__action {
    margin-left: 8px;
  }

  .q-field {
    &__label {
      font-size: 20px !important;
      padding-bottom: 52px !important;
    }
  }
}
</style>
