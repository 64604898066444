export enum ProfileFeaturesFlag
  {
  /// <summary>
  /// No profile search features enabled.
  /// </summary>
  None = 0,
  /// <summary>
  /// Ability to search tangles.
  /// </summary>
  TanglesSearch = 1,
  /// <summary>
  /// Ability to search tangles with the advanced search flag.
  /// </summary>
  AdvancedTanglesSearch = 1 << 1,
}

export const ProfileFeaturesFlags = [ProfileFeaturesFlag.None, ProfileFeaturesFlag.TanglesSearch];

export const getProfileFeatureName = (feature: ProfileFeaturesFlag, defaultException = true): string => {
  switch (feature) {
    case ProfileFeaturesFlag.TanglesSearch:
      return 'Tangles';
    case ProfileFeaturesFlag.AdvancedTanglesSearch:
      return 'Advanced Tangles';
    default:
      if (defaultException) {
        throw new Error(`Feature ${feature} does not exist.`);
      }
      return 'None';
  }
};
