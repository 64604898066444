import { TValidationRule } from '@/validation/common/rules';
import { shortDateFormat } from '@/utils/dateUtil';

export function isAfter<T> (getter: (model: T | null) => Date | null, errorMessage?: string): TValidationRule<T, Date> {
  return async (context) => {
    const value = context.value;
    if (value === null) {
      return true;
    }

    const compareDate = getter(context.model);
    if (compareDate === null) {
      return true;
    }

    return value > compareDate || (errorMessage ?? `Must be after ${shortDateFormat(compareDate)}`);
  };
}

export function isBefore<T> (getter: (model: T | null) => Date | null, errorMessage?: string): TValidationRule<T, Date> {
  return async (context) => {
    const value = context.value;
    if (value === null) {
      return true;
    }

    const compareDate = getter(context.model);
    if (compareDate === null) {
      return true;
    }

    return value < compareDate || (errorMessage ?? `Must be before ${shortDateFormat(compareDate)}`);
  };
}
