<template>
  <div class="m-date-picker">
    <span class="m-date-picker__label">{{ dateLabel }}</span>
    <div>
      <q-input
        class="m-date-picker__input"
        filled
        v-model="dateString"
        type="date"
        :hint="hint"
        dense
        :error="isError"
        :disable="disable"
        :error-message="errorMessage"
        @update:model-value="dateChanged"
      />
      <q-btn
        v-if="showOffsetDateButton"
        :label="dateOffsetLabel"
        :disable="disableOffsetButton"
        outline
        color="pl-navy-900"
        @click="dateOffsets"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { setEnd, setStart, twoDigitDates } from '@/utils/dateUtil';
import { isNullOrWhitespace } from '@/utils/stringUtils';
import { computed, defineComponent, PropType, ref, watch } from 'vue';

export default defineComponent({
  props: {
    defaultDate: {
      type: Object as PropType<Date | null>,
      required: false
    },
    isError: {
      type: Boolean,
      required: false
    },
    errorMessage: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    appendHint: {
      type: String,
      required: false
    },
    isStart: {
      type: Boolean,
      required: true
    },
    showOffsetDateButton: {
      type: Boolean,
      required: false
    },
    offsetDate: {
      type: Object as PropType<Date | null>,
      required: false
    },
    dateOffsetLabel: {
      type: String,
      required: false
    },
    yearsOffset: {
      type: Number,
      default: 1,
      required: false
    },
    isUpdatingDate: {
      type: Boolean,
      default: false,
      required: false
    },
    disable: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  emits: ['dateChanged'],
  setup (props, { emit }) {
    const dateString = ref(formatDate(props.defaultDate));
    const dateValue = computed(() => toDate(dateString.value));
    const dateLabel = computed(() => props.label ?? 'Date');
    const hint = computed(() => props.appendHint ? `MM/DD/YYYY - ${props.appendHint}` : 'MM/DD/YYYY');
    const disableOffsetButton = computed(() => !props.offsetDate);

    watch(() => props.defaultDate, defaultDateChanged);

    function formatDate (date: Date | null | undefined): string | null {
      return !date ? null : `${date.getUTCFullYear()}-${twoDigitDates(date.getUTCMonth() + 1)}-${twoDigitDates(date.getUTCDate())}`;
    }

    function toDate (dateString: string | null): Date | null {
      if (isNullOrWhitespace(dateString)) {
        return null;
      }

      const dateParts = dateString!.split('-').map(p => Number.parseInt(p, 10));
      const year = dateParts[0];
      const month = dateParts[1] - 1;
      const day = dateParts[2];
      const date = new Date(year, month, day);
      if (props.isStart) {
        setStart(date);
      } else {
        setEnd(date);
      }
      return date;
    }

    function dateChanged (newDateString: string | null) {
      if (!isNullOrWhitespace(dateString.value)) {
        const dateParts = newDateString!.split('-');
        const year = dateParts[0];
        if (year.length > 4) {
          dateParts[0] = year.slice(year.length - 4);
          const cleanDateString = dateParts.join('-');
          dateString.value = cleanDateString;
        }
      }
      emit('dateChanged', dateValue.value);
    }

    function addYears (date: Date) {
      date.setUTCFullYear(date.getUTCFullYear() + props.yearsOffset);
      if (!props.isStart) {
        date.setUTCDate(date.getUTCDate() - 1);
      }
    }

    function dateOffsets () {
      if (props.offsetDate) {
        const newDate = new Date(props.offsetDate);
        addYears(newDate);
        dateString.value = formatDate(newDate);
        emit('dateChanged', dateValue.value);
      }
    }

    function defaultDateChanged () {
      if (props.isUpdatingDate) {
        // We don't need to emit an update here as the consuming component is the one that made the update.
        dateString.value = formatDate(props.defaultDate);
      }
    }

    return {
      dateString,
      dateLabel,
      hint,
      dateChanged,
      dateOffsets,
      disableOffsetButton
    };
  }

});
</script>

<style lang="scss">
.m-date-picker {
  &__label {
    color: $color-gray-700;
  }

  &__input {
    display: inline-flex;
    width: 370px;
    padding-right: 10px;
  }
}
</style>
