
import { defineComponent } from 'vue';
import AsyncContent from '@/components/AsyncContent.vue';
import TopNav from '@/components/TopNav.vue';
import LeftDrawer from '@/components/LeftDrawer.vue';
import { provideStore as provideSubscriptionsStore } from '@/store/subscriptions/subscriptions';

export default defineComponent({
  name: 'Subscriptions',
  components: {
    AsyncContent,
    TopNav,
    LeftDrawer
  },
  setup () {
    provideSubscriptionsStore();
  }
});
