import { Settings } from '@/settings';
import {
  ITransactionsState,
  TSetServiceType,
  TSetUsages,
  TSetFrom,
  TSetTo,
  TLoadUsages,
  TSetSubscriptionId,
  ITransactionsStore,
  AggregateUsagePeriodType,
  ISubscriptionUsage,
  IServerSubscriptionUsageResponse,
  IServerSubscriptionUsageRequest
} from '@/store/contracts/subscriptions/transactions';
import { chainHandlers, DefaultIStatusHandler, handleErrorStatusResult, IStatusHandler } from '@/store/utils/apiResponseHandling';
import { sendRequest } from '@/store/utils/axiosUtils';
import { toApp as usageToApp } from '@/store/subscriptions/mapping/subscriptionUsage';
import { inject, provide, reactive, readonly } from 'vue';
import { store as validationStore } from '@/store/validation';
import filterValidationModel from '@/validation/transactionsFilterValidationModel';
import { SubscriptionServiceType } from '@/store/contracts/subscriptions/subscriptionServiceType';

const TransactionsStoreKey = Symbol('TransactionsStore');

function setSubscriptionId (state: ITransactionsState): TSetSubscriptionId {
  return (subscriptionId) => {
    state.subscriptionId = subscriptionId;
    state.dailyUsages = [];
    state.monthlyUsages = [];
    state.totalUsages = 0;
    state.filterModel = {
      serviceType: null,
      from: null,
      to: null
    };
  };
}

function setUsages (state: ITransactionsState): TSetUsages {
  return (dailyUsages, monthlyUsages, totalUsages) => {
    state.dailyUsages = dailyUsages;
    state.monthlyUsages = monthlyUsages;
    state.totalUsages = totalUsages;
  };
}

function setServiceType (state: ITransactionsState): TSetServiceType {
  return (serviceType) => {
    state.filterModel.serviceType = serviceType;
  };
}

function setFrom (state: ITransactionsState): TSetFrom {
  return (from) => {
    state.filterModel.from = from;
  };
}

function setTo (state: ITransactionsState): TSetTo {
  return (to) => {
    state.filterModel.to = to;
  };
}

function fetchUsagesForAggregate (
  state: ITransactionsState,
  aggregateType: AggregateUsagePeriodType,
  handler?: Partial<IStatusHandler<IServerSubscriptionUsageResponse>>
): Promise<[boolean, ISubscriptionUsage[]]> {
  return new Promise<[boolean, ISubscriptionUsage[]]>((resolve) => {
    sendRequest<IServerSubscriptionUsageResponse>(
      `${Settings.adminApiBaseUrl}/admin/v1/subscription/usage/load`,
      'post',
      {
        SubscriptionId: state.subscriptionId,
        To: state.filterModel.to,
        From: state.filterModel.from,
        AggregateType: aggregateType,
        ServiceType: SubscriptionServiceType[state.filterModel.serviceType ?? SubscriptionServiceType.Vision]
      } as IServerSubscriptionUsageRequest
    ).then((response) => handleErrorStatusResult(response, chainHandlers({
      onSuccess: async (u) => resolve([true, u.Usages.map(usageToApp)]),
      onError: async (err) => {
        await DefaultIStatusHandler.onError(err);
        resolve([false, []]);
      },
      onNotAuthenticated: async (err) => {
        await DefaultIStatusHandler.onNotAuthenticated(err);
        resolve([false, []]);
      },
      onNotFound: async (err) => {
        await DefaultIStatusHandler.onNotFound(err);
        resolve([false, []]);
      }
    }, handler)));
  });
}

function loadUsages (state: ITransactionsState): TLoadUsages {
  return async (handler) => {
    const isValid = await validationStore.doValidation({
      model: filterValidationModel,
      value: state.filterModel
    });
    if (state.subscriptionId && isValid) {
      const results = await Promise.all([
        fetchUsagesForAggregate(state, AggregateUsagePeriodType.Daily, handler),
        fetchUsagesForAggregate(state, AggregateUsagePeriodType.Monthly, handler)
      ]);

      if (results.none(result => !result[0])) {
        const dailyUsages = results[0][1];
        const monthlyUsages = results[1][1];
        const totalUsages = dailyUsages.length > 0 ? dailyUsages.map(a => a.transactionCount).reduce((a, b) => a + b) : 0;
        setUsages(state)(dailyUsages, monthlyUsages, totalUsages);
      }
    }
  };
}

export function createState (): ITransactionsState {
  validationStore.clear(filterValidationModel);
  return reactive({
    subscriptionId: null,
    dailyUsages: [],
    monthlyUsages: [],
    totalUsages: 0,
    filterModel: {
      from: null,
      to: null,
      serviceType: null
    }
  });
}

export function createForState (state: ITransactionsState): ITransactionsStore {
  return {
    state: readonly(state),
    setSubscriptionId: setSubscriptionId(state),
    setUsages: setUsages(state),
    setServiceType: setServiceType(state),
    setFrom: setFrom(state),
    setTo: setTo(state),
    loadUsages: loadUsages(state)
  };
}

export function provideStore (): ITransactionsStore {
  const state = createState();
  provide(TransactionsStoreKey, state);
  return createForState(state);
}

export function useStore (): ITransactionsStore {
  const state = inject<ITransactionsState>(TransactionsStoreKey);
  if (state === undefined) {
    throw new Error('Using TransactionStore before providing it!');
  }
  return createForState(state);
}
