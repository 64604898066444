import { render } from "./AddServiceDialog.vue?vue&type=template&id=71ad42ae"
import script from "./AddServiceDialog.vue?vue&type=script&lang=ts"
export * from "./AddServiceDialog.vue?vue&type=script&lang=ts"

import "./AddServiceDialog.vue?vue&type=style&index=0&id=71ad42ae&lang=scss"
script.render = render

export default script
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QCard,QCardSection,QSelect,QInput,QBtn});
