
import { QTableColumn } from '@/components/utils/qTable';
import { computed, defineComponent, ref, Ref } from 'vue';
import AddServiceDialog from '@/components/subscriptions/AddServiceDialog.vue';
import { provideStore as provideCreateStore } from '@/store/subscriptions/create';
import { isNullOrWhitespace } from '@/utils/stringUtils';
import debounce from 'quasar/src/utils/debounce.js';;
import validationModel, {
  SubscriptionCreateFieldName as FieldName
} from '@/validation/subscriptionCreateValidationModel';
import { useStore as useValidationStore } from '@/store/validation';
import { useRouter } from 'vue-router';
import { ISubscriptionServiceModel } from '@/store/contracts/subscriptions/addService';
import { getServiceName, SubscriptionServiceType } from '@/store/contracts/subscriptions/subscriptionServiceType';
import { getFlagValues } from '@/utils/hasFlag';
import { VisionFeaturesFlag, VisionFeaturesFlags, getVisionFeatureName } from '@/store/contracts/subscriptions/visionFeaturesFlag';
import { ProfileFeaturesFlag, ProfileFeaturesFlags, getProfileFeatureName } from '@/store/contracts/subscriptions/profileFeaturesFlag';
import { ISubscriptionService } from '@/store/contracts/subscriptions/subscriptions';
import { SubscriptionRoutes } from '@/router/routes/subscriptions';
import { shortDateFormat } from '@/utils/dateUtil';
import DatePicker from '@/components/date/DatePicker.vue';
import { ISubscriptionCreateModel } from '@/store/contracts/subscriptions/create';
import { subscriptionServiceTypeGridDisplay } from '@/utils/units';
import { formatSeconds } from '@/utils/time';

export default defineComponent({
  components: {
    AddServiceDialog,
    DatePicker
  },
  setup () {
    const createStore = provideCreateStore();
    const validationStore = useValidationStore();
    const router = useRouter();
    const isLoading = ref(false);

    const subscriptionCreateModel = computed(() => createStore.state.model as ISubscriptionCreateModel);

    const debouncedSearch = debounce(createStore.searchSystems, 1500);

    const columns: QTableColumn[] = [
      {
        name: 'name',
        label: 'Service Name',
        align: 'left',
        field: row => getServiceName(row.serviceType)
      },
      {
        name: 'contractStartDate',
        label: 'Contract Start Date',
        align: 'left',
        field: row => shortDateFormat(row.contractStartDate)
      },
      {
        name: 'contractEndDate',
        label: 'Contract End Date',
        align: 'left',
        field: row => shortDateFormat(row.contractEndDate)
      },
      {
        name: 'contractQuota',
        label: 'Contract Quota',
        align: 'left',
        field: row => quotaDisplay(row.contractQuota, row.contractQuota)
      },
      {
        name: 'monthlyQuota',
        label: 'Monthly Quota',
        align: 'left',
        field: row => quotaDisplay(row.monthlyQuota, row.contractQuota)
      },
      {
        name: 'dailyQuota',
        label: 'Daily Quota',
        align: 'left',
        field: row => quotaDisplay(row.dailyQuota, row.contractQuota)
      },
      {
        name: 'features',
        label: 'Features',
        align: 'left',
        field: row => getFlags(row.serviceType, row.featuresFlag)
      },
      {
        name: 'actions',
        label: '',
        align: 'right',
        field: row => row
      }
    ];

    function quotaDisplay (serviceType: SubscriptionServiceType, quota?: number) {
      switch (serviceType) {
        case SubscriptionServiceType.Transcription:
          return formatSeconds(quota);
        default:
          return quota?.toLocaleString('en-US');
      }
    }

    function getFlags (serviceType: SubscriptionServiceType, featureFlag: number) : string {
      switch (serviceType) {
        case SubscriptionServiceType.Vision: {
          const visionFlags = getFlagValues(VisionFeaturesFlags, featureFlag).filter(f => f !== VisionFeaturesFlag.None);
          if (visionFlags.length === 0) {
            return 'None';
          }
          return visionFlags.map(f => getVisionFeatureName(f)).join(', ');
        }
        case SubscriptionServiceType.Profile: {
          const profileFlags = getFlagValues(ProfileFeaturesFlags, featureFlag).filter(f => f !== ProfileFeaturesFlag.None);
          if (profileFlags.length === 0) {
            return 'None';
          }
          return profileFlags.map(f => getProfileFeatureName(f)).join(', ');
        }
        default:
          return 'None';
      }
    }

    function fieldIsError (fieldName: FieldName): boolean {
      return validationStore.fieldIsError(validationModel.modelName, fieldName);
    }

    function fieldErrorMessage (fieldName: FieldName): string {
      return validationStore.fieldErrorMessage(validationModel.modelName, fieldName);
    }
    const label = computed({
      get: () => createStore.state.model.label ?? ' ',
      set: createStore.setLabel
    });

    const machineId = computed({
      get: () => createStore.state.model.machineId ?? ' ',
      set: createStore.setMachineId
    });
    const machineIdIsError = computed(() => fieldIsError(FieldName.MachineId));
    const machineIdErrorMessage = computed(() => fieldErrorMessage(FieldName.MachineId));

    const activeDate = computed({
      get: () => createStore.state.model.activeDate,
      set: createStore.setActiveDate
    });

    const expiresDate = computed({
      get: () => createStore.state.model.expiresDate,
      set: createStore.setExpiresDate
    });

    const note = computed({
      get: () => createStore.state.model.note,
      set: createStore.setNote
    });

    const services = computed(() => createStore.state.model.services);

    const hasData = computed(() => services.value.length > 0);

    const selectedSystem = computed({
      get: () => createStore.state.systemInfo,
      set: (s) => createStore.setSystemInfo(s)
    });

    const systemSearchResults = computed(() => createStore.state.systemSearchResults);

    const isAddingService = ref(false);
    const editSubscriptionService: Ref<ISubscriptionService | null> = ref(null);

    function startAddingService (service: ISubscriptionService | null) {
      editSubscriptionService.value = service;
      isAddingService.value = true;
    }

    const selectOptions = computed(() => createStore.state.systemSearchResults.map((si) => ({ label: si.customerName, value: si })));

    const isShowingSearch = ref(false);

    function filterSearch (val, update) {
      createStore.setSearchTerm(val);
      if (!isNullOrWhitespace(val)) {
        debouncedSearch({
          onSuccess: () => update()
        });
      } else {
        update();
      }
    }

    function addService (service: ISubscriptionService) {
      if (editSubscriptionService.value?.index !== null && editSubscriptionService.value?.index !== undefined) {
        const updatedServices = [...createStore.state.model.services];
        updatedServices[editSubscriptionService.value.index] = service;
        createStore.setServices(updatedServices as ISubscriptionService[]);
      } else {
        createStore.setServices([...createStore.state.model.services as ISubscriptionService[], service]);
      }
    }

    function deleteService (service: ISubscriptionServiceModel) {
      createStore.setServices(createStore.state.model.services.filter(s => s !== service) as ISubscriptionService[]);
    }

    async function save () {
      isLoading.value = true;
      try {
        const subscriptionId = await createStore.save();
        if (subscriptionId) {
          router.push({ name: SubscriptionRoutes.view, params: { subscriptionId: subscriptionId } });
        } else {
          isShowingSearch.value = false;
        }
      } finally {
        isLoading.value = false;
      }
    }

    async function cancel () {
      validationStore.clear(validationModel);
      router.back();
    }

    function activeDateChanged (newDate: Date | null) {
      activeDate.value = newDate;
    }

    function expiresDateChanged (newDate: Date | null) {
      expiresDate.value = newDate;
    }

    const dateOffsetLabel = 'Set 1 year from Activation Date';

    return {
      activeDate,
      activeDateChanged,
      expiresDate,
      expiresDateChanged,
      dateOffsetLabel,
      columns,
      isAddingService,
      startAddingService,
      isShowingSearch,
      selectedSystem,
      systemSearchResults,
      filterSearch,
      note,
      label,
      machineId,
      machineIdIsError,
      machineIdErrorMessage,
      services,
      selectOptions,
      hasData,
      save,
      cancel,
      deleteService,
      subscriptionCreateModel,
      FieldName,
      fieldIsError,
      fieldErrorMessage,
      addService,
      editSubscriptionService,
      shortDateFormat,
      getFlags,
      quotaDisplay,
      formatSeconds,
      getServiceName,
      subscriptionServiceTypeGridDisplay,
      isLoading
    };
  }
});
