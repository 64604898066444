export enum SubscriptionServiceType {
  None = 'None',
  Vision = 'Vision',
  Translation = 'Translation',
  Transcription = 'Transcription',
  Profile = 'Profile'
}

export const SubscriptionServiceTypes = [SubscriptionServiceType.None, SubscriptionServiceType.Vision, SubscriptionServiceType.Translation, SubscriptionServiceType.Transcription, SubscriptionServiceType.Profile];

export const getServiceName = (serviceType: SubscriptionServiceType): string => {
  switch (serviceType) {
    case SubscriptionServiceType.None:
      return 'Not Selected';
    case SubscriptionServiceType.Vision:
      return 'Vision';
    case SubscriptionServiceType.Translation:
      return 'Translation';
    case SubscriptionServiceType.Transcription:
      return 'Transcription';
    case SubscriptionServiceType.Profile:
      return 'Profile';
    default:
      throw new Error(`Service type ${serviceType} does not exist.`);
  }
};
