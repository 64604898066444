
import { QTableColumn } from '@/components/utils/qTable';
import { SubscriptionRoutes } from '@/router/routes/subscriptions';
import { ISubscriptionListingModel, ISubscriptionService } from '@/store/contracts/subscriptions/subscriptions';
import { SubscriptionServiceType, getServiceName } from '@/store/contracts/subscriptions/subscriptionServiceType';
import { computed, defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { VisionFeaturesFlags, getVisionFeatureName } from '@/store/contracts/subscriptions/visionFeaturesFlag';
import { ProfileFeaturesFlags, getProfileFeatureName } from '@/store/contracts/subscriptions/profileFeaturesFlag';
import { getFlagValues } from '@/utils/hasFlag';
import copyToClipboard from 'quasar/src/utils/copy-to-clipboard.js';import useQuasar from 'quasar/src/composables/use-quasar.js';import QInput from 'quasar/src/components/input/QInput.js';;
import { provideStore as provideSubscriptionScore } from '@/store/subscriptions/subscription';
import AddServiceDialog from '@/components/subscriptions/AddServiceDialog.vue';
import EditFieldActions from '@/components/EditFieldActions.vue';
import { mapReadonly, toCreateModel } from '@/store/subscriptions/mapping/subscriptionListingModel';
import { toServer } from '@/store/subscriptions/mapping/subscriptionService';
import labelValidationModel, { UpdateLabelFieldName } from '@/validation/updateSubscriptionLabelValidationModel';
import { useStore as useValidationStore } from '@/store/validation';
import { useInlineEditable } from '@/components/utils/inlineEditable';
import { ApiTestRoutes } from '@/router/routes/apiTest';
import { useStore as useUploadStore } from '@/store/apiTest/upload';
import activeExpiresDateValidationModel, { UpdateActiveExpiresDateFieldName } from '@/validation/updateActiveExpiresDateValidationModel';
import { shortDateFormat } from '@/utils/dateUtil';
import EditActiveExpiresDatesDialog from '@/components/subscriptions/EditActiveExpiresDatesDialog.vue';
import { subscriptionServiceTypeGridDisplay } from '@/utils/units';
import { formatSeconds } from '@/utils/time';

export default defineComponent({
  components: {
    AddServiceDialog,
    EditFieldActions,
    EditActiveExpiresDatesDialog
  },
  props: {
    subscriptionId: {
      type: String,
      required: true
    }
  },
  async setup (props) {
    const noteInput = ref<QInput | null>(null);
    const noteInputEl = computed(() => (noteInput.value?.$el ?? null) as HTMLElement | null);
    const noteInputInputEl = computed(() => noteInputEl.value?.querySelector('textarea') ?? null);

    const labelInput = ref<QInput | null>(null);
    const labelInputEl = computed(() => (labelInput.value?.$el ?? null) as HTMLElement | null);
    const labelInputInputEl = computed(() => labelInputEl.value?.querySelector('input') ?? null);

    const subscriptionStore = provideSubscriptionScore();
    const validationStore = useValidationStore();
    const uploadStore = useUploadStore();
    const router = useRouter();
    const $q = useQuasar();

    await subscriptionStore.loadSubscription(props.subscriptionId);

    const subscription = computed(() => subscriptionStore.state.subscription);
    const subscriptionCreateModel = computed(() => toCreateModel(subscription.value as ISubscriptionListingModel));

    const activationDate = computed(() => subscription.value?.activeDate ?? null);
    const activationDateDisplay = computed(() => shortDateFormat(activationDate.value));
    const expirationDate = computed(() => subscription.value?.expiresDate ?? null);
    const expirationDateDisplay = computed(() => shortDateFormat(expirationDate.value));
    const customerName = computed(() => subscription.value?.customerName);
    const machineId = computed(() => subscription.value?.machineId);
    const subscriptionKey = computed(() => subscription.value?.subscriptionKey);
    const note = computed(() => subscription.value?.note ?? null);
    const label = computed(() => subscription.value?.label ?? null);
    const servicesStore = computed(() => subscription.value!.services.map(mapReadonly));
    const servicesUi = ref<ISubscriptionService[] | null>(null);
    const servicesDisplay = computed(() => servicesUi.value ?? servicesStore.value);

    const isEnabled = computed(() => subscriptionStore.isEnabled);
    const hasVisionService = computed(() => {
      return subscription.value?.services.some(service => service.serviceType === SubscriptionServiceType.Vision) ?? false;
    });

    const columns: QTableColumn[] = [
      {
        name: 'serviceName',
        label: 'Service',
        align: 'left',
        field: row => getServiceName(row.serviceType)
      },
      {
        name: 'contractStartDate',
        label: 'Contract Start Date',
        align: 'left',
        field: row => shortDateFormat(row.contractStartDate)
      },
      {
        name: 'contractEndDate',
        label: 'Contract End Date',
        align: 'left',
        field: row => shortDateFormat(row.contractEndDate)
      },
      {
        name: 'remainingContractUnits',
        label: 'Remaining Units',
        align: 'left',
        field: row => quotaDisplay(row.serviceType, row.remainingContractUnits)
      },
      {
        name: 'contractQuota',
        label: 'Contract Quota',
        align: 'left',
        field: row => quotaDisplay(row.serviceType, row.contractQuota)
      },
      {
        name: 'monthlyQuota',
        label: 'Monthly Quota',
        align: 'left',
        field: row => quotaDisplay(row.serviceType, row.monthlyQuota)
      },
      {
        name: 'dailyQuota',
        label: 'Daily Quota',
        align: 'left',
        field: row => quotaDisplay(row.serviceType, row.dailyQuota)
      },
      {
        name: 'features',
        label: 'Features',
        align: 'left',
        field: row => getFeatures(row.serviceType, row.featuresFlag)
      },
      {
        name: 'actions',
        label: '',
        align: 'right',
        field: row => row
      }
    ];

    function quotaDisplay (serviceType: SubscriptionServiceType, quota?: number) {
      switch (serviceType) {
        case SubscriptionServiceType.Transcription:
          return formatSeconds(quota);
        default:
          return quota?.toLocaleString('en-US');
      }
    }

    function getFeatures (serviceType: SubscriptionServiceType, featuresFlag: number) {
      if (featuresFlag === 0) {
        return 'None';
      }

      switch (serviceType) {
        case SubscriptionServiceType.Vision: {
          const features = getFlagValues(VisionFeaturesFlags, featuresFlag);
          const featureNames: string[] = features.map(f => getVisionFeatureName(f, false));
          featureNames.shift();
          return featureNames.join(', ');
        }
        case SubscriptionServiceType.Profile: {
          const features = getFlagValues(ProfileFeaturesFlags, featuresFlag);
          const featureNames: string[] = features.map(f => getProfileFeatureName(f, false));
          featureNames.shift();
          return featureNames.join(', ');
        }
        default:
          return '';
      }
    }

    const showSubscriptionKey = ref(false);
    const subscriptionKeyInputType = computed(() => showSubscriptionKey.value ? 'text' : 'password');
    const showSubscriptionKeyTooltip = computed(() => showSubscriptionKey.value ? 'Hide subscription key' : 'Show subscription key');
    const showSubscriptionKeyIcon = computed(() => showSubscriptionKey.value ? 'mdi-eye-off-outline' : 'mdi-eye-outline');

    function toggleShowSubscriptionKey () {
      showSubscriptionKey.value = !showSubscriptionKey.value;
    }

    async function copySubscriptionKeyToClipboard () {
      const text = subscription.value?.subscriptionKey ?? null;
      if (text !== null) {
        await copyToClipboard(text);

        $q.notify({
          type: 'positive',
          message: 'Subscription key copied to clipboard',
          timeout: 3000
        });
      }
    }

    async function confirmGenerateNewKey () {
      if (subscription.value !== null) {
        const confirmed = await new Promise((resolve) => {
          $q.dialog({
            title: 'Generate new key?',
            message: 'Are you sure you want to generate a new key for this subscription?  This will cause any clients using the current key to be non-functional until they begin using the new key.',
            cancel: true,
            persistent: true
          }).onOk(() => resolve(true)).onCancel(() => resolve(false));
        });
        if (confirmed) {
          await subscriptionStore.generateNewKey();
          $q.notify({
            type: 'positive',
            message: 'New subscription key generated',
            timeout: 3000
          });
        }
      }
    }

    const isAddingService = ref(false);
    const editSubscriptionService = ref<ISubscriptionService | null>(null);
    const tableHasData = computed(() => servicesDisplay.value.length > 0);

    function addService (service: ISubscriptionService | null) {
      editSubscriptionService.value = service;
      isAddingService.value = true;
    }

    function finishAddingService (service: ISubscriptionService) {
      servicesUi.value = servicesStore.value;
      if (editSubscriptionService.value) {
        servicesUi.value[editSubscriptionService.value.index!] = service;
      } else {
        servicesUi.value.push(service);
      }
      updateServices();
    }

    function deleteService (service: ISubscriptionService) {
      $q.dialog({
        title: 'Delete Service Confirmation',
        message: 'Are you sure you want to delete this service subscription?',
        cancel: true,
        persistent: true
      }).onOk(() => {
        servicesUi.value = servicesStore.value;
        servicesUi.value.splice(servicesUi.value.indexOf(service), 1);
        updateServices();
      });
    }

    async function updateServices () {
      await subscriptionStore.updateServices({
        Services: servicesUi.value?.map(toServer) ?? []
      });

      // null out our ui updated services after a fresh load.
      servicesUi.value = null;
    }

    function viewTransactions () {
      router.push({ name: SubscriptionRoutes.transactions, params: { subscriptionId: props.subscriptionId } });
    }

    function confirmDisableSubscription () {
      $q.dialog({
        title: 'Disable Subscription Confirmation',
        message: 'Are you sure you want to disable this subscription?',
        cancel: true,
        persistent: true
      }).onOk(() => {
        subscriptionStore.disableSubscription();
      });
    }

    async function enableSubscription () {
      await subscriptionStore.enableSubscription();
    }

    const {
      compText: noteText
      , isEditing: isUpdatingNote
      , isRequestingUpdate: isRequestingNoteUpdate
      , doUpdate: doUpdateNote
      , stopEditing: stopUpdatingNote
      , startEditing: startUpdatingNote
    } = useInlineEditable(
      () => subscription.value?.note ?? null
      , note
      , noteInputInputEl
      , async (comp) => {
        await subscriptionStore.updateNote({
          note: comp.editText.value
        },
        {
          onSuccess: async () => stopUpdatingNote()
        });
      }
    );
    const isEditingDates = ref(false);
    function startEditingDates () {
      isEditingDates.value = true;
    }
    const activationDateIsError = computed(() => validationStore.fieldIsError(activeExpiresDateValidationModel.modelName, UpdateActiveExpiresDateFieldName.ActiveDate));
    const activationDateErrorMessage = computed(() => validationStore.fieldErrorMessage(activeExpiresDateValidationModel.modelName, UpdateActiveExpiresDateFieldName.ActiveDate));
    const expirationDateIsError = computed(() => validationStore.fieldIsError(activeExpiresDateValidationModel.modelName, UpdateActiveExpiresDateFieldName.ExpiresDate));
    const expirationDateErrorMessage = computed(() => validationStore.fieldErrorMessage(activeExpiresDateValidationModel.modelName, UpdateActiveExpiresDateFieldName.ExpiresDate));

    const {
      compText: labelText
      , isEditing: isUpdatingLabel
      , isRequestingUpdate: isRequestingLabelUpdate
      , doUpdate: doUpdateLabel
      , stopEditing: stopUpdatingLabel
      , startEditing: startUpdatingLabel
    } = useInlineEditable(
      () => subscription.value?.label ?? null
      , label
      , labelInputInputEl
      , async (comp) => {
        await subscriptionStore.updateLabel({
          label: comp.editText.value
        }, {
          onSuccess: async () => await comp.stopEditing()
        });
      }
      , async () => {
        validationStore.clear(labelValidationModel);
      }, async () => {
        validationStore.clear(labelValidationModel);
      });
    const isLabelInError = computed(() => validationStore.fieldIsError(labelValidationModel.modelName, UpdateLabelFieldName.Label));
    const labelErrorMessage = computed(() => validationStore.fieldErrorMessage(labelValidationModel.modelName, UpdateLabelFieldName.Label));

    function goToImageAnalysis () {
      uploadStore.setSubscriptionKey(subscriptionKey.value ?? null);
      router.push({ name: ApiTestRoutes.imageAnalysis });
    }

    function subscriptionListView () {
      router.push({ name: SubscriptionRoutes.list });
    }

    return {
      screen: $q.screen,
      subscription,
      customerName,
      machineId,
      label,
      note,
      subscriptionKey,
      activationDate,
      activationDateDisplay,
      expirationDate,
      expirationDateDisplay,
      columns,
      subscriptionListView,
      getServiceName,
      subscriptionKeyInputType,
      toggleShowSubscriptionKey,
      copySubscriptionKeyToClipboard,
      showSubscriptionKeyTooltip,
      showSubscriptionKeyIcon,
      confirmGenerateNewKey,
      viewTransactions,
      isEnabled,
      confirmDisableSubscription,
      enableSubscription,
      noteText,
      isUpdatingNote,
      isRequestingNoteUpdate,
      noteInput,
      startUpdatingNote,
      stopUpdatingNote,
      doUpdateNote,
      subscriptionCreateModel,
      toCreateModel,
      servicesDisplay,
      isAddingService,
      addService,
      finishAddingService,
      deleteService,
      tableHasData,
      labelText,
      labelInput,
      startUpdatingLabel,
      stopUpdatingLabel,
      doUpdateLabel,
      isUpdatingLabel,
      isRequestingLabelUpdate,
      isLabelInError,
      labelErrorMessage,
      goToImageAnalysis,
      startEditingDates,
      isEditingDates,
      activationDateIsError,
      activationDateErrorMessage,
      expirationDateIsError,
      expirationDateErrorMessage,
      editSubscriptionService,
      hasVisionService,
      shortDateFormat,
      getFeatures,
      quotaDisplay,
      subscriptionServiceTypeGridDisplay,
      formatSeconds
    };
  }
});
