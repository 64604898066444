import { IUpdateActiveExpiresDateModel } from '@/store/contracts/subscriptions/subscription';
import { ValidationModel } from '.';
import { isAfter, isBefore } from '@/validation/common/dateRules';
import { getValueRequiredRule } from './common/rules';

export enum UpdateActiveExpiresDateFieldName {
  ActiveDate = 'activeDate',
  ExpiresDate = 'expiresDate'
}

class UpdateActiveExpiresDateValidationModel extends ValidationModel<IUpdateActiveExpiresDateModel> {
  constructor () {
    super();

    this.setField(UpdateActiveExpiresDateFieldName.ActiveDate, {
      getter: (model) => model?.activeDate ?? null,
      rules: [
        getValueRequiredRule((value: Date | null) => value !== null)('Activation Date is required.'),
        isBefore((model) => model?.expiresDate ?? null, 'Should be before Expiration Date')
      ]
    });

    this.setField(UpdateActiveExpiresDateFieldName.ExpiresDate, {
      getter: (model) => model?.expiresDate ?? null,
      rules: [
        getValueRequiredRule((value: Date | null) => value !== null)('Expiration Date is required.'),
        isAfter((model) => model?.activeDate ?? null, 'Should be after Activation Date')
      ]
    });
  }

  get modelName (): string {
    return 'UpdateActiveExpiresDateValidationModel';
  }
}

export default new UpdateActiveExpiresDateValidationModel();
