
export function hasFlag<T extends number> (flag: T): (flags: T | number) => boolean {
  return (flags) => (flags & flag) === flag;
}

/**
 * Get an array of enum values based on the given populated flag value.
 * @param possibleValues the enum values
 * @param flag the populated flag value
 * @returns an array containing the values of the enum that are populated in the flag
 */
export function getFlagValues<E extends number> (possibleValues: E[], flag: number | null): E[] {
  if (flag === null) {
    return [];
  }
  return possibleValues.filter(v => hasFlag(v)(flag));
}

export function toFlag<E extends number> (values: E[]): number {
  return values.reduce((prev, next) => prev | next, 0);
}
