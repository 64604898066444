
import { computed, defineComponent, Ref, ref, watch } from 'vue';
import { useStore as useSubscriptionsStore } from '@/store/subscriptions/subscriptions';

export default defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      required: true
    }
  },
  setup (props, { emit }) {
    const subscriptionsStore = useSubscriptionsStore();

    const isOpen = computed({
      get: () => props.modelValue,
      set: (v) => emit('update:modelValue', v)
    });

    watch(() => props.modelValue, reset);

    const isRefreshingSubscriptions = ref(false);
    const refreshSubscriptionsSuccess: Ref<boolean | null> = ref(null);
    const refreshSubscriptionsSuccessIcon = computed(() => {
      if (refreshSubscriptionsSuccess.value === null) {
        return [null, null];
      }

      return refreshSubscriptionsSuccess.value ? ['mdi-check-circle', 'green'] : ['mdi-alpha-x-circle green', 'red'];
    });

    async function refreshCachedSubscriptions () {
      isRefreshingSubscriptions.value = true;

      refreshSubscriptionsSuccess.value = await subscriptionsStore.refreshCachedSubscriptions();

      isRefreshingSubscriptions.value = false;
    }

    function reset () {
      isRefreshingSubscriptions.value = false;
      refreshSubscriptionsSuccess.value = null;
    }

    function close () {
      isOpen.value = false;
    }

    return {
      isOpen,
      refreshCachedSubscriptions,
      refreshSubscriptionsSuccess,
      refreshSubscriptionsSuccessIcon,
      isRefreshingSubscriptions,
      close
    };
  }
});
