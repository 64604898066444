import { ISystemInfoSearchModel } from '@/store/contracts/subscriptions/create';
import { impl } from '@/utils/impl';

export interface IServerSystemInfoSearchModel {
  SystemId: string;
  MachineId: string;
  MachineLabel: string;
  CustomerName: string;
}

export interface IServerSystemInforSearchResponse {
  Systems: IServerSystemInfoSearchModel[];
}

export function toApp (serverModel: IServerSystemInfoSearchModel): ISystemInfoSearchModel {
  return impl<ISystemInfoSearchModel>({
    systemId: serverModel.SystemId,
    machineId: serverModel.MachineId,
    machineLabel: serverModel.MachineLabel,
    customerName: serverModel.CustomerName
  });
}
