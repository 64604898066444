
import { computed, defineComponent, ref, watch } from 'vue';
import TransactionChart from '@/components/subscriptions/TransactionChart.vue';
import { provideStore as provideTransactionStore } from '@/store/subscriptions/transactions';
import { provideStore as provideSubscriptionScore } from '@/store/subscriptions/subscription';
import { useRouter } from 'vue-router';
import { goBack } from '@/router/goBack';
import { SubscriptionRoutes } from '@/router/routes/subscriptions';
import {
  SubscriptionServiceTypes,
  SubscriptionServiceType
} from '@/store/contracts/subscriptions/subscriptionServiceType';
import validationModel, {
  TransactionsFilterFieldName as FieldName
} from '@/validation/transactionsFilterValidationModel';
import { AggregateUsagePeriodType, ISubscriptionUsage } from '@/store/contracts/subscriptions/transactions';
import { useFieldsValidation } from '@/components/utils/fieldValidation';
import DatePicker from '@/components/date/DatePicker.vue';
import { delay } from '@/utils/systemUtils';
import { subscriptionServiceTypeChartDisplay } from '@/utils/units';

export default defineComponent({
  components: {
    TransactionChart,
    DatePicker
  },
  props: {
    subscriptionId: {
      type: String,
      required: true
    }
  },
  async setup (props) {
    const transactionStore = provideTransactionStore();
    const subscriptionStore = provideSubscriptionScore();
    const router = useRouter();

    const { validationRefs } = useFieldsValidation(
      validationModel,
      ref(true),
      [FieldName.ServiceType, FieldName.From, FieldName.To]);

    transactionStore.setSubscriptionId(props.subscriptionId);
    watch(() => props.subscriptionId, transactionStore.setSubscriptionId);

    await transactionStore.loadUsages();
    await subscriptionStore.loadSubscription(props.subscriptionId);

    function formatWithCommas (number: number) {
      return number.toLocaleString('en-US');
    }

    const dailyUsages = computed(() => transactionStore.state.dailyUsages as ISubscriptionUsage[]);
    const monthlyUsages = computed(() => transactionStore.state.monthlyUsages as ISubscriptionUsage[]);
    const totalUsages = computed(() => formatWithCommas(transactionStore.state.totalUsages));
    const activeServiceType = ref(SubscriptionServiceType.Vision);
    const units = computed(() => subscriptionServiceTypeChartDisplay(activeServiceType.value));

    const serviceTypeOptions = SubscriptionServiceTypes.filter(t => t !== SubscriptionServiceType.None).map(t => ({
      label: SubscriptionServiceType[t],
      value: t
    }));

    const serviceType = computed({
      get: () => transactionStore.state.filterModel.serviceType ?? SubscriptionServiceType.Vision,
      set: (val) => {
        const changed = val !== serviceType.value;
        transactionStore.setServiceType(val);
        if (changed) {
          setDefaultDates();
        }
      }
    });

    const fromDate = computed({
      get: () => transactionStore.state.filterModel.from,
      set: (val) => transactionStore.setFrom(val)
    });

    const toDate = computed({
      get: () => transactionStore.state.filterModel.to,
      set: (val) => transactionStore.setTo(val)
    });

    const updatingChart = ref(false);
    async function updateChart () {
      updatingChart.value = true;
      await transactionStore.loadUsages();
      activeServiceType.value = transactionStore.state.filterModel.serviceType ?? SubscriptionServiceType.Vision;
      updatingChart.value = false;
    }

    const canClearFilters = computed(() =>
      (transactionStore.state.filterModel.serviceType !== null &&
      transactionStore.state.filterModel.serviceType !== SubscriptionServiceType.Vision) ||
      transactionStore.state.filterModel.from !== null ||
      transactionStore.state.filterModel.to !== null);

    async function clearFilters () {
      transactionStore.setServiceType(null);
      transactionStore.setTo(null);
      transactionStore.setFrom(null);
      await updateChart();
    }

    function fromDateChanged (newDate: Date | null) {
      fromDate.value = newDate;
    }

    function toDateChanged (newDate: Date | null) {
      toDate.value = newDate;
    }

    const isUpdatingDates = ref(false);
    async function setDefaultDates () {
      isUpdatingDates.value = true;
      const service = subscriptionStore.state.subscription?.services.find(s => s.serviceType === serviceType.value);
      fromDate.value = service?.contractStartDate ?? subscriptionStore.state.subscription?.activeDate ?? null;
      toDate.value = service?.contractEndDate ?? subscriptionStore.state.subscription?.expiresDate ?? null;
      await delay(100); // Give the UI a short delay to update before flipping back our update bool
      isUpdatingDates.value = false;
    }

    setDefaultDates();

    return {
      TransactionChart,
      back: goBack(router, { name: SubscriptionRoutes.list }),
      dailyUsages,
      monthlyUsages,
      totalUsages,
      serviceTypeOptions,
      serviceType,
      fromDate,
      toDate,
      FieldName,
      validationRefs,
      updatingChart,
      updateChart,
      clearFilters,
      canClearFilters,
      AggregateUsagePeriodType,
      fromDateChanged,
      toDateChanged,
      isUpdatingDates,
      units
    };
  }
});
