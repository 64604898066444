import { render } from "./SubscriptionListPage.vue?vue&type=template&id=700b620d"
import script from "./SubscriptionListPage.vue?vue&type=script&lang=ts"
export * from "./SubscriptionListPage.vue?vue&type=script&lang=ts"

import "./SubscriptionListPage.vue?vue&type=style&index=0&id=700b620d&lang=scss"
script.render = render

export default script
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QToggle,QBtn,QTable,QCheckbox});
