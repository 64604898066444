import { ISubscriptionCreateModel } from '@/store/contracts/subscriptions/create';
import { ValidationModel } from '@/validation';
import { getValueRequiredRule, stringRequiredRule } from '@/validation/common/rules';
import { isAfter, isBefore } from '@/validation/common/dateRules';

export enum SubscriptionCreateFieldName {
  Label = 'label',
  MachineId = 'machineId',
  ActiveDate = 'activeDate',
  ExpiresDate = 'expiresDate',
  Note = 'note',
  Services = 'services'
}

class SubscriptionCreateValidationModel extends ValidationModel<ISubscriptionCreateModel> {
  constructor () {
    super();

    this.setField(SubscriptionCreateFieldName.Label, {
      getter: (model) => model?.label ?? null,
      rules: [
        stringRequiredRule('Label is required')
      ]
    });

    this.setField(SubscriptionCreateFieldName.MachineId, {
      getter: (model) => model?.machineId ?? null,
      rules: [
        stringRequiredRule('Enter a machine id or search/select a system.')
      ]
    });

    this.setField(SubscriptionCreateFieldName.ActiveDate, {
      getter: (model) => model?.activeDate ?? null,
      rules: [
        getValueRequiredRule((value: Date | null) => value !== null)('Activation Date is required.'),
        isBefore((model) => model?.expiresDate ?? null, 'Should be before Expiration Date')
      ]
    });

    this.setField(SubscriptionCreateFieldName.ExpiresDate, {
      getter: (model) => model?.expiresDate ?? null,
      rules: [
        getValueRequiredRule((value: Date | null) => value !== null)('Expiration Date is required.'),
        isAfter((model) => model?.activeDate ?? null, 'Should be after Activation Date')
      ]
    });
  }

  public get modelName (): string {
    return 'SubscriptionCreate';
  }
}

export default new SubscriptionCreateValidationModel();
