
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import { VisionFeaturesFlag, VisionFeaturesFlags, getVisionFeatureName } from '@/store/contracts/subscriptions/visionFeaturesFlag';
import { ProfileFeaturesFlag, ProfileFeaturesFlags, getProfileFeatureName } from '@/store/contracts/subscriptions/profileFeaturesFlag';
import { SubscriptionServiceType, SubscriptionServiceTypes, getServiceName } from '@/store/contracts/subscriptions/subscriptionServiceType';
import validationModel, { SubscriptionServiceFieldName as FieldName } from '@/validation/subscriptionServiceValidationModel';
import { provideStore as provideServiceStore } from '@/store/subscriptions/addService';
import { fromAdd } from '@/store/subscriptions/mapping/subscriptionService';
import { getFlagValues, toFlag } from '@/utils/hasFlag';
import { ISubscriptionCreateModel } from '@/store/contracts/subscriptions/create';
import { useFieldsValidation } from '@/components/utils/fieldValidation';
import { ISubscriptionServiceModel } from '@/store/contracts/subscriptions/addService';
import { Settings } from '@/settings';
import DatePicker from '@/components/date/DatePicker.vue';

export default defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    subscriptionCreateModel: {
      type: Object as PropType<ISubscriptionCreateModel>,
      required: true
    },
    subscriptionService: {
      type: Object as PropType<ISubscriptionServiceModel | null>,
      required: false
    }
  },
  components: {
    DatePicker
  },
  setup (props, { emit }) {
    const isOpen = computed({
      get: () => props.modelValue,
      set: (v) => emit('update:modelValue', v)
    });

    const contractQuotaLabel = computed(() => {
      switch (serviceType.value) {
        case SubscriptionServiceType.None:
          return 'Contract Quota';
        case SubscriptionServiceType.Vision:
          return 'Contract Quota (Lookups)';
        case SubscriptionServiceType.Translation:
          return 'Contract Quota (Characters)';
        case SubscriptionServiceType.Transcription:
          return 'Contract Quota (Hours)';
        case SubscriptionServiceType.Profile:
          return 'Contract Quota (Searches)';
        default:
          throw new Error(`Service Type ${serviceType.value} not found.`);
      }
    });

    const monthlyQuotaLabel = computed(() => {
      switch (serviceType.value) {
        case SubscriptionServiceType.None:
          return 'Monthly Quota';
        case SubscriptionServiceType.Vision:
          return 'Monthly Quota (Lookups)';
        case SubscriptionServiceType.Translation:
          return 'Monthly Quota (Characters)';
        case SubscriptionServiceType.Transcription:
          return 'Monthly Quota (Hours)';
        case SubscriptionServiceType.Profile:
          return 'Monthly Quota (Searches)';
        default:
          throw new Error(`Service Type ${serviceType.value} not found.`);
      }
    });

    const dailyQuotaLabel = computed(() => {
      switch (serviceType.value) {
        case SubscriptionServiceType.None:
          return 'Daily Quota';
        case SubscriptionServiceType.Vision:
          return 'Daily Quota (Lookups)';
        case SubscriptionServiceType.Translation:
          return 'Daily Quota (Characters)';
        case SubscriptionServiceType.Transcription:
          return 'Daily Quota (Hours)';
        case SubscriptionServiceType.Profile:
          return 'Daily Quota (Searches)';
        default:
          throw new Error(`Service Type ${serviceType.value} not found.`);
      }
    });

    const addServiceStore = provideServiceStore();

    const noServiceTypeSelected = computed(() => serviceType.value === SubscriptionServiceType.None);

    addServiceStore.setCreateModel(props.subscriptionCreateModel);

    const availableServices = SubscriptionServiceTypes.map(v => ({
      label: getServiceName(v),
      value: v
    }));

    const { validationStore, validationRefs } = useFieldsValidation(
      validationModel,
      ref(true),
      [FieldName.ServiceType, FieldName.DailyQuota, FieldName.MonthlyQuota, FieldName.ContractStartDate, FieldName.ContractEndDate, FieldName.ContractQuota, FieldName.FeaturesFlag]);

    const serviceType = computed({
      get: () => addServiceStore.state.model.serviceType,
      set: (s) => {
        resetQuotas(s); // Quotas use different units based on service type so we want to rest them when the service type changes.
        addServiceStore.setServiceType(s);
      }
    });

    const contractStartDate = computed({
      get: () => addServiceStore.state.model.contractStartDate,
      set: addServiceStore.setContractStartDate
    });

    const contractEndDate = computed({
      get: () => addServiceStore.state.model.contractEndDate,
      set: addServiceStore.setContractEndDate
    });

    const contractQuota = computed({
      get: () => getQuota('Contract', serviceType.value),
      set: (s) => setQuota(s, 'Contract', serviceType.value)
    });

    const monthlyQuota = computed({
      get: () => getQuota('Monthly', serviceType.value),
      set: (s) => setQuota(s, 'Monthly', serviceType.value)
    });

    const dailyQuota = computed({
      get: () => getQuota('Daily', serviceType.value),
      set: (s) => setQuota(s, 'Daily', serviceType.value)
    });

    const visionFeaturesFlag = computed({
      get: () => getFlagValues(VisionFeaturesFlags.filter(f => f !== VisionFeaturesFlag.None), addServiceStore.state.model.featuresFlag),
      set: (value) => addServiceStore.setFeaturesFlag(toFlag(value))
    });

    const profileFeaturesFlag = computed({
      get: () => getFlagValues(ProfileFeaturesFlags.filter(f => f !== ProfileFeaturesFlag.None), addServiceStore.state.model.featuresFlag),
      set: (value) => addServiceStore.setFeaturesFlag(toFlag(value))
    });

    const tanglesApiKey = computed({
      get: () => addServiceStore.state.model.tanglesApiKey,
      set: (s) => {
        if (s) {
          addServiceStore.setContractQuota(2147483647);
        } else if (addServiceStore.state.model.contractQuota === 2147483647) {
          addServiceStore.setContractQuota(null);
        }
        addServiceStore.setTanglesApiKey(s);
      }
    });

    const availableFeatures = computed(() => {
      switch (serviceType.value) {
        case SubscriptionServiceType.Vision:
          return VisionFeaturesFlags.filter(f => f !== VisionFeaturesFlags[0]).map(f => ({
            label: getVisionFeatureName(f),
            value: f
          }));
        case SubscriptionServiceType.Profile:
          return ProfileFeaturesFlags.filter(f => f !== ProfileFeaturesFlags[0]).map(f => ({
            label: getProfileFeatureName(f),
            value: f
          }));
        default:
          return [];
      }
    });

    function resetQuotas (serviceType: SubscriptionServiceType | null) {
      if (serviceType === SubscriptionServiceType.Profile && tanglesApiKey.value) {
        addServiceStore.setContractQuota(2147483647);
      } else {
        addServiceStore.setContractQuota(null);
      }
      addServiceStore.setMonthlyQuota(null);
      addServiceStore.setDailyQuota(null);
    }

    function getQuota (contractType: 'Contract' | 'Monthly' | 'Daily', serviceType: SubscriptionServiceType | null) {
      if (!serviceType) {
        serviceType = SubscriptionServiceType.None;
      }
      switch (contractType) {
        case 'Contract':
          return normalizeQuotaFromServer(addServiceStore.state.model.contractQuota, serviceType);
        case 'Monthly':
          return normalizeQuotaFromServer(addServiceStore.state.model.monthlyQuota, serviceType);
        case 'Daily':
          return normalizeQuotaFromServer(addServiceStore.state.model.dailyQuota, serviceType);
        default:
          break;
      }
    }

    function setQuota (quota: number | null | undefined, contractType: 'Contract' | 'Monthly' | 'Daily', serviceType: SubscriptionServiceType | null) {
      if (!serviceType) {
        serviceType = SubscriptionServiceType.None;
      }
      switch (contractType) {
        case 'Contract':
          addServiceStore.setContractQuota(normalizeQuotaToServer(quota, serviceType));
          break;
        case 'Monthly':
          addServiceStore.setMonthlyQuota(normalizeQuotaToServer(quota, serviceType));
          break;
        case 'Daily':
          addServiceStore.setDailyQuota(normalizeQuotaToServer(quota, serviceType));
          break;
        default:
          break;
      }
    }

    function normalizeQuotaToServer (value: number | null | undefined, serviceType: SubscriptionServiceType) {
      if (!value) {
        return null;
      }
      switch (serviceType) {
        case SubscriptionServiceType.Transcription:
          return value * 3600; // Converting hours to seconds for storage on the backend.
        default:
          return parseFloat(value.toFixed(0)); // Backend quotas are long and cannot have decimals.
      }
    }

    function normalizeQuotaFromServer (value: number | null, serviceType: SubscriptionServiceType) {
      if (!value) {
        return null;
      }
      switch (serviceType) {
        case SubscriptionServiceType.Transcription:
          return parseFloat((value / 3600).toFixed(2)); // Converting seconds to hours for view on frontend
        default:
          return parseFloat(value.toFixed(0)); // Backend quotas are long and cannot have decimals.
      }
    }

    const hasVisionFeatures = computed(() => {
      return (serviceType.value === SubscriptionServiceType.Vision);
    });
    const hasProfileFeatures = computed(() => {
      return (serviceType.value === SubscriptionServiceType.Profile);
    });
    const hasTanglesFeatures = computed(() => {
      return (serviceType.value === SubscriptionServiceType.Profile && (profileFeaturesFlag.value.includes(ProfileFeaturesFlag.TanglesSearch) || profileFeaturesFlag.value.includes(ProfileFeaturesFlag.AdvancedTanglesSearch)));
    });

    function resetState () {
      addServiceStore.setContractStartDate(props.subscriptionService ? props.subscriptionService?.contractStartDate : props.subscriptionCreateModel.activeDate);
      addServiceStore.setContractEndDate(props.subscriptionService ? props.subscriptionService?.contractEndDate : props.subscriptionCreateModel.expiresDate);
      addServiceStore.setContractQuota(props.subscriptionService?.contractQuota ?? Settings.defaultContractQuota);
      addServiceStore.setMonthlyQuota(props.subscriptionService?.monthlyQuota ?? Settings.defaultMonthlyQuota);
      addServiceStore.setDailyQuota(props.subscriptionService?.dailyQuota ?? Settings.defaultDailyQuota);
      addServiceStore.setServiceType(props.subscriptionService?.serviceType ?? SubscriptionServiceType.None);
      addServiceStore.setFeaturesFlag(props.subscriptionService?.featuresFlag ?? VisionFeaturesFlag.Tags);
      addServiceStore.setTanglesApiKey(props.subscriptionService?.tanglesApiKey ?? null);
      addServiceStore.setIndex(props.subscriptionService?.index ?? props.subscriptionCreateModel.services.length);
    }

    watch(() => props.modelValue, resetState);

    watch(() => props.subscriptionCreateModel, () => addServiceStore.setCreateModel(props.subscriptionCreateModel));

    async function add () {
      const valid = await addServiceStore.validate();
      if (valid) {
        emit('service-added', fromAdd(addServiceStore.state.model));
        isOpen.value = false;
      }
    }

    async function cancel () {
      validationStore.clear(validationModel);
      isOpen.value = false;
    }

    const dialogTitle = computed(() => props.subscriptionService ? 'Edit Service' : 'Add Service');
    const confirmWord = computed(() => props.subscriptionService ? 'Save' : 'Add');

    function contractStartDateChanged (startDate: Date | null) {
      contractStartDate.value = startDate;
    }

    function contractEndDateChanged (endDate: Date | null) {
      contractEndDate.value = endDate;
    }

    return {
      isOpen,
      contractStartDate,
      contractStartDateChanged,
      contractEndDate,
      contractEndDateChanged,
      contractQuota,
      monthlyQuota,
      dailyQuota,
      availableServices,
      serviceType,
      availableFeatures,
      add,
      cancel,
      hasVisionFeatures,
      hasProfileFeatures,
      hasTanglesFeatures,
      validationRefs,
      FieldName,
      visionFeaturesFlag,
      profileFeaturesFlag,
      tanglesApiKey,
      dialogTitle,
      confirmWord,
      contractQuotaLabel,
      monthlyQuotaLabel,
      dailyQuotaLabel,
      noServiceTypeSelected,
      resetQuotas
    };
  }
});
