import { RouteLocationRaw, Router } from 'vue-router';

export function goBack (router: Router, fallback: RouteLocationRaw): () => void {
  return () => {
    const beforeLoc = window.location.href;
    router.back();
    const afterLoc = window.location.href;
    if (beforeLoc === afterLoc) {
      router.push(fallback);
    }
  };
}
