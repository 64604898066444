
import { computed, defineComponent, onMounted, onUnmounted, ref } from 'vue';
import { QTableColumn } from '@/components/utils/qTable';
import { useRouter } from 'vue-router';
import { ISubscriptionListingModel, ISubscriptionService } from '@/store/contracts/subscriptions/subscriptions';
import { SubscriptionRoutes } from '@/router/routes/subscriptions';
import { getServiceName } from '@/store/contracts/subscriptions/subscriptionServiceType';
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { useStore as useSubscriptionsStore } from '@/store/subscriptions/subscriptions';
import { shortDateFormat } from '@/utils/dateUtil';
import DiagnosticsDialog from '@/components/subscriptions/DiagnosticsDialog.vue';

export default defineComponent({
  components: {
    DiagnosticsDialog
  },
  async setup () {
    const subscriptionsStore = useSubscriptionsStore();
    const router = useRouter();
    const $q = useQuasar();

    const keysPressed = { m: false };
    function onKeyDown (e: KeyboardEvent) {
      keysPressed[e.key] = true;
      if (keysPressed.m && e.ctrlKey && e.altKey && Object.keys(keysPressed).length === 3) {
        toggleDiagnostics();
      }
    }
    onMounted(() => {
      window.addEventListener('keydown', (e) => onKeyDown(e));
      window.addEventListener('keyup', (e) => delete keysPressed[e.key]);
    });
    onUnmounted(() => {
      window.removeEventListener('keydown', (e) => onKeyDown(e));
      window.removeEventListener('keyup', (e) => delete keysPressed[e.key]);
    });

    function displayServices (services: ISubscriptionService[]) {
      if (services.length === 0) {
        return 'None';
      }
      return services.map(s => getServiceName(s.serviceType)).join(', ');
    }
    const subscriptions = computed(() => subscriptionsStore.state.subscriptions?.filter(sub => showDisabledSubscriptions.value || subscriptionsStore.isEnabled(sub)));

    const showDisabledSubscriptions = computed({
      get: () => subscriptionsStore.state.showDisabled,
      set: (value) => subscriptionsStore.setShowDisabled(value)
    });

    enum ColumnNames {
      label = 'label',
      machineId = 'machineId',
      services = 'services',
      activeDate = 'activeDate',
      expiresDate = 'expiresDate',
      enabled = 'enabled',
      actions = 'actions'
    }

    const visibleColumns = computed(() => Object.keys(ColumnNames).filter((c) => showDisabledSubscriptions.value || c !== ColumnNames.enabled));

    const columns: QTableColumn[] = [
      {
        name: ColumnNames.enabled,
        label: 'Enabled',
        align: 'left',
        field: (row: ISubscriptionListingModel) => subscriptionsStore.isEnabled(row)
      },
      {
        name: ColumnNames.label,
        label: 'Label',
        align: 'left',
        field: (row: ISubscriptionListingModel) => row.label,
        sortable: true
      },
      {
        name: ColumnNames.machineId,
        label: 'Machine ID',
        align: 'left',
        field: (row: ISubscriptionListingModel) => row.machineId
      },
      {
        name: ColumnNames.services,
        label: 'Services',
        align: 'left',
        field: (row: ISubscriptionListingModel) => displayServices(row.services)
      },
      {
        name: ColumnNames.activeDate,
        label: 'Activation Date',
        align: 'left',
        field: (row: ISubscriptionListingModel) => shortDateFormat(row.activeDate),
        sortable: true,
        sort: (a, b) => new Date(a).getTime() - new Date(b).getTime()
      },
      {
        name: ColumnNames.expiresDate,
        label: 'Expiration Date',
        align: 'left',
        field: (row: ISubscriptionListingModel) => shortDateFormat(row.expiresDate),
        sortable: true,
        sort: (a, b) => new Date(a).getTime() - new Date(b).getTime()
      },
      {
        name: ColumnNames.actions,
        label: '',
        align: 'left',
        field: (row: ISubscriptionListingModel) => row
      }
    ];

    await subscriptionsStore.loadSubscriptions();

    function viewSubscription (subscription: ISubscriptionListingModel) {
      router.push({ name: SubscriptionRoutes.view, params: { subscriptionId: subscription.subscriptionId } });
    }

    function generateSubscription () {
      router.push({ name: SubscriptionRoutes.generate });
    }

    function confirmDisableSubscription (subscription: ISubscriptionListingModel) {
      $q.dialog({
        title: 'Disable Subscription Confirmation',
        message: 'Are you sure you want to disable this subscription?',
        cancel: true,
        persistent: true
      }).onOk(() => {
        subscriptionsStore.disableSubscription(subscription.subscriptionId);
      });
    }

    async function enableSubscription (subscription: ISubscriptionListingModel) {
      await subscriptionsStore.enableSubscription(subscription.subscriptionId);
    }

    const isDiagnosticsOpen = ref(false);
    function toggleDiagnostics () {
      isDiagnosticsOpen.value = !isDiagnosticsOpen.value;
    }

    return {
      columns,
      visibleColumns,
      subscriptions,
      screen: $q.screen,
      viewSubscription,
      generateSubscription,
      isEnabled: subscriptionsStore.isEnabled,
      confirmDisableSubscription,
      enableSubscription,
      showDisabledSubscriptions,
      isDiagnosticsOpen
    };
  }
});
