import { SubscriptionServiceType } from '@/store/contracts/subscriptions/subscriptionServiceType';
import { IStatusHandler } from '@/store/utils/apiResponseHandling';
import { DeepReadonly } from 'vue';

export interface IServerSubscriptionUsage {
  ServiceType: SubscriptionServiceType;
  RecordedDay: Date | string;
  TransactionCount: number;
}

export interface ISubscriptionUsage {
  serviceType: SubscriptionServiceType;
  recordedDay: Date;
  transactionCount: number;
}

export enum AggregateUsagePeriodType {
  None = 'None',
  Daily = 'Daily',
  Monthly = 'Monthly'
}

export interface IServerSubscriptionUsageRequest {
  From: Date;
  To: Date;
  ServiceType: SubscriptionServiceType;
  AggregateType: AggregateUsagePeriodType;
}

export interface IServerSubscriptionUsageResponse {
  Usages: IServerSubscriptionUsage[];
}

export interface ITransactionsFilterModel {
  serviceType: SubscriptionServiceType | null;
  from: Date | null;
  to: Date | null;
}

export interface ITransactionsState {
  subscriptionId: string | null;
  dailyUsages: ISubscriptionUsage[];
  monthlyUsages: ISubscriptionUsage[];
  totalUsages: number;
  filterModel: ITransactionsFilterModel;
}

export type TSetSubscriptionId = (subscriptionId) => void;
export type TSetUsages = (dailyUsages: ISubscriptionUsage[], monthlyUsages: ISubscriptionUsage[], totalUsages: number) => void;
export type TSetServiceType = (serviceType: SubscriptionServiceType | null) => void;
export type TSetFrom = (from: Date | null) => void;
export type TSetTo = (to: Date | null) => void;
export type TLoadUsages = (handler?: Partial<IStatusHandler<IServerSubscriptionUsageResponse>>) => Promise<void>;

export interface ITransactionsStore {
  state: DeepReadonly<ITransactionsState>;
  setSubscriptionId: TSetSubscriptionId;
  setUsages: TSetUsages;
  setServiceType: TSetServiceType;
  setFrom: TSetFrom;
  setTo: TSetTo;
  loadUsages: TLoadUsages;
}
