
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import { useStore as useSubscriptionStore } from '@/store/subscriptions/subscription';
import validationModel, { UpdateActiveExpiresDateFieldName as FieldName } from '@/validation/updateActiveExpiresDateValidationModel';
import { useFieldsValidation } from '@/components/utils/fieldValidation';
import DatePicker from '@/components/date/DatePicker.vue';
import { IUpdateActiveExpiresDateModel } from '@/store/contracts/subscriptions/subscription';
import { mapReadonly } from '@/store/subscriptions/mapping/subscriptionListingModel';
import { ISubscriptionService } from '@/store/contracts/subscriptions/subscriptions';
import { toServer } from '@/store/subscriptions/mapping/subscriptionService';

export default defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    activeDate: {
      type: Object as PropType<Date | null>,
      required: true
    },
    expiresDate: {
      type: Object as PropType<Date | null>,
      required: true
    }
  },
  components: {
    DatePicker
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const subscriptionStore = useSubscriptionStore();

    const isOpen = computed({
      get: () => props.modelValue,
      set: (v) => emit('update:modelValue', v)
    });

    const activeDateRef = ref(props.activeDate);
    const expiresDateRef = ref(props.expiresDate);
    const model = computed<IUpdateActiveExpiresDateModel>(() => {
      return {
        activeDate: activeDateRef.value,
        expiresDate: expiresDateRef.value
      };
    });

    const updateContractDates = ref(true);

    const { validationStore, validationRefs } = useFieldsValidation(
      validationModel,
      ref(true),
      [FieldName.ActiveDate, FieldName.ExpiresDate]);

    function resetState () {
      activeDateRef.value = props.activeDate;
      expiresDateRef.value = props.expiresDate;
      updateContractDates.value = true;
      isSaving.value = false;
    }

    watch(() => props.modelValue, resetState);

    const isSaving = ref(false);
    async function save () {
      isSaving.value = true;
      const valid = await validationStore.doValidation({
        model: validationModel,
        value: model.value
      });
      if (valid) {
        await subscriptionStore.updateActiveExpiresDate(model.value);

        if (updateContractDates.value) {
          let services: ISubscriptionService[] | undefined = subscriptionStore.state.subscription?.services.map(mapReadonly);
          services = services?.map(s => {
            s.contractStartDate = activeDateRef.value;
            s.contractEndDate = expiresDateRef.value;
            return s;
          });

          if (services && services.length > 0) {
            await subscriptionStore.updateServices({
              Services: services.map(toServer)
            });
          }
        }

        isOpen.value = false;
      }
      isSaving.value = false;
    }

    async function cancel () {
      validationStore.clear(validationModel);
      isOpen.value = false;
    }

    function activeDateChanged (newDate: Date | null) {
      activeDateRef.value = newDate;
    }

    function expiresDateChanged (newDate: Date | null) {
      expiresDateRef.value = newDate;
    }

    const dateOffsetLabel = 'Set 1 year from Activation Date';

    return {
      isOpen,
      validationRefs,
      FieldName,
      isSaving,
      save,
      cancel,
      activeDateChanged,
      expiresDateChanged,
      updateContractDates,
      dateOffsetLabel,
      activeDateRef
    };
  }
});
