import { render } from "./DatePicker.vue?vue&type=template&id=7d8f4962"
import script from "./DatePicker.vue?vue&type=script&lang=ts"
export * from "./DatePicker.vue?vue&type=script&lang=ts"

import "./DatePicker.vue?vue&type=style&index=0&id=7d8f4962&lang=scss"
script.render = render

export default script
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QBtn});
