import { ISubscriptionCreateModel } from '@/store/contracts/subscriptions/create';
import { IServerSubscriptionService, toServer as serviceToServer } from '@/store/subscriptions/mapping/subscriptionService';
import { impl } from '@/utils/impl';

export interface IServerSubscriptionCreateModel {
  Label: string;
  MachineId: string;
  ActiveDate: Date | null;
  ExpiresDate: Date | null;
  Note: string | null;
  Services: IServerSubscriptionService[];
}

export function toServer (app: ISubscriptionCreateModel): IServerSubscriptionCreateModel {
  return impl<IServerSubscriptionCreateModel>({
    Label: app.label ?? '',
    MachineId: app.machineId ?? '',
    ActiveDate: app.activeDate,
    ExpiresDate: app.expiresDate,
    Note: app.note,
    Services: app.services.map(serviceToServer)
  });
}
