import { ISubscriptionCreateModel } from '@/store/contracts/subscriptions/create';
import { IUpdateActiveExpiresDateModel } from '@/store/contracts/subscriptions/subscription';
import { ISubscriptionListingModel, ISubscriptionService } from '@/store/contracts/subscriptions/subscriptions';
import { IServerSubscriptionService, toApp as serviceToApp } from '@/store/subscriptions/mapping/subscriptionService';
import { impl } from '@/utils/impl';
import { DeepReadonly } from 'vue';

export interface IRemainingContractUnits {
  Vision: number;
  Translation: number;
  Transcription: number;
  Profile: number;
}

export interface IServerSubscriptionListingModel {
  Label: string;
  CustomerName: string;
  SubscriptionId: string;
  SubscriptionKey: string;
  MachineId: string;
  Services: IServerSubscriptionService[];
  ActiveDate: Date | null;
  ExpiresDate: Date | null;
  Note: string | null;
}

export interface IServerSubscriptionListingResponse {
  Subscriptions: IServerSubscriptionListingModel[];
}

export interface IServerSubscriptionResponse {
  Subscription: IServerSubscriptionListingModel;
  RemainingContractUnits: IRemainingContractUnits | null;
}

export interface IServerUpdateDateModel {
  ActiveDate: Date | null;
  ExpiresDate: Date | null;
}

export function toServerDate (model: IUpdateActiveExpiresDateModel): IServerUpdateDateModel {
  return impl<IServerUpdateDateModel>({
    ActiveDate: model.activeDate,
    ExpiresDate: model.expiresDate
  });
}

export function subscriptiontoApp (server: IServerSubscriptionResponse): ISubscriptionListingModel {
  return impl<ISubscriptionListingModel>({
    label: server.Subscription.Label,
    customerName: server.Subscription.CustomerName,
    subscriptionId: server.Subscription.SubscriptionId,
    subscriptionKey: server.Subscription.SubscriptionKey,
    machineId: server.Subscription.MachineId,
    services: server.Subscription.Services.map((s, i) => serviceToApp(s, i, server.RemainingContractUnits)),
    activeDate: server.Subscription.ActiveDate === null ? null : new Date(server.Subscription.ActiveDate),
    expiresDate: server.Subscription.ExpiresDate === null ? null : new Date(server.Subscription.ExpiresDate),
    note: server.Subscription.Note
  });
}

export function subscriptionListingToApp (server: IServerSubscriptionListingModel): ISubscriptionListingModel {
  return impl<ISubscriptionListingModel>({
    label: server.Label,
    customerName: server.CustomerName,
    subscriptionId: server.SubscriptionId,
    subscriptionKey: server.SubscriptionKey,
    machineId: server.MachineId,
    services: server.Services.map((s, i) => serviceToApp(s, i, null)),
    activeDate: server.ActiveDate === null ? null : new Date(server.ActiveDate),
    expiresDate: server.ExpiresDate === null ? null : new Date(server.ExpiresDate),
    note: server.Note
  });
}

export function mapReadonly (service: ISubscriptionService | DeepReadonly<ISubscriptionService>): ISubscriptionService {
  return impl<ISubscriptionService>({
    serviceType: service.serviceType,
    contractStartDate: service.contractStartDate,
    contractEndDate: service.contractEndDate,
    remainingContractUnits: service.remainingContractUnits,
    contractQuota: service.contractQuota,
    monthlyQuota: service.monthlyQuota,
    dailyQuota: service.dailyQuota,
    featuresFlag: service.featuresFlag,
    tanglesApiKey: service.tanglesApiKey,
    createModel: service.createModel as ISubscriptionCreateModel,
    index: service.index
  });
}

export function toCreateModel (listing: ISubscriptionListingModel): ISubscriptionCreateModel {
  return impl<ISubscriptionCreateModel>({
    label: listing.label,
    machineId: listing.machineId || '',
    activeDate: listing.activeDate,
    expiresDate: listing.expiresDate,
    note: listing.note || '',
    services: listing.services.map(mapReadonly)
  });
}
