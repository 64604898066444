export const dayMillis = (1000 * 60 * 60 * 24);

export function daysMillis (days: number): number {
  return dayMillis * days;
}

export function minusDays (date: Date | number, days: number): number {
  return (typeof date === 'number' ? date : date.getTime()) - daysMillis(days);
}

export function plusDays (date: Date | number, days: number): number {
  return (typeof date === 'number' ? date : date.getTime()) + daysMillis(days);
}

export function formatSeconds (seconds: number | null | undefined): string {
  if (seconds === null || seconds === undefined) {
    return '0h';
  }
  // Convert seconds to hours, minutes, and seconds in this format 00h 00m 00s
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - (hours * 3600)) / 60);
  const secondsRemainder = seconds - (hours * 3600) - (minutes * 60);

  let timeString = '';
  if (hours > 0) {
    timeString += hours + 'h ';
  }
  if (minutes > 0) {
    timeString += minutes + 'm ';
  }
  if (secondsRemainder > 0) {
    timeString += secondsRemainder.toFixed(0) + 's ';
  }
  return timeString;
}
