export enum VisionFeaturesFlag
  {
  /// <summary>
  /// No additional features enabled for Vision
  /// </summary>
  None = 0,
  /// <summary>
  /// Tags feature for Vision
  /// </summary>
  Tags = 1,
  /// <summary>
  /// Adult content feature for Vision
  /// </summary>
  Adult = 1 << 1,
}

export const VisionFeaturesFlags = [VisionFeaturesFlag.None, VisionFeaturesFlag.Adult, VisionFeaturesFlag.Tags];

export const getVisionFeatureName = (feature: VisionFeaturesFlag, defaultException = true): string => {
  switch (feature) {
    case VisionFeaturesFlag.Tags:
      return 'Tags';
    case VisionFeaturesFlag.Adult:
      return 'Adult';
    default:
      if (defaultException) {
        throw new Error(`Feature ${feature} does not exist.`);
      }
      return 'None';
  }
};
