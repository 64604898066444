import { render } from "./DiagnosticsDialog.vue?vue&type=template&id=2bc9c79d"
import script from "./DiagnosticsDialog.vue?vue&type=script&lang=ts"
export * from "./DiagnosticsDialog.vue?vue&type=script&lang=ts"

import "./DiagnosticsDialog.vue?vue&type=style&index=0&id=2bc9c79d&lang=scss"
script.render = render

export default script
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QCard,QCardSection,QBtn,QTooltip,QIcon});
