import { render } from "./SubscriptionGeneratePage.vue?vue&type=template&id=dc245a84"
import script from "./SubscriptionGeneratePage.vue?vue&type=script&lang=ts"
export * from "./SubscriptionGeneratePage.vue?vue&type=script&lang=ts"

import "./SubscriptionGeneratePage.vue?vue&type=style&index=0&id=dc245a84&lang=scss"
script.render = render

export default script
import QForm from 'quasar/src/components/form/QForm.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QForm,QToggle,QSelect,QItem,QItemSection,QInput,QTable,QTr,QTd,QTooltip,QBtn});
