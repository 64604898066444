import { impl } from '@/utils/impl';
import { inject, provide, reactive, readonly } from 'vue';
import {
  IAddServiceState,
  IAddServiceStore,
  TSetContractStartDate,
  TSetContractEndDate,
  TSetContractQuota,
  TSetMonthlyQuota,
  TSetDailyQuota,
  TSetCreateModel,
  TSetFeaturesFlag,
  TSetTanglesApiKey,
  TSetIndex,
  TSetModel,
  TSetServiceType,
  TValidate
} from '@/store/contracts/subscriptions/addService';
import { store as validationStore } from '@/store/validation';
import validationModel from '@/validation/subscriptionServiceValidationModel';
import { SubscriptionServiceType } from '@/store/contracts/subscriptions/subscriptionServiceType';
import { Settings } from '@/settings';

const AddServiceStoreKey = Symbol('AddServiceStore');

function setModel (state: IAddServiceState): TSetModel {
  return (model) => {
    state.model = model;
  };
}

function setContractStartDate (state: IAddServiceState): TSetContractStartDate {
  return (contractStartDate) => {
    state.model.contractStartDate = contractStartDate;
  };
}

function setContractEndDate (state: IAddServiceState): TSetContractEndDate {
  return (contractEndDate) => {
    state.model.contractEndDate = contractEndDate;
  };
}

function setContractQuota (state: IAddServiceState): TSetContractQuota {
  return (contractQuota) => {
    let val = contractQuota;
    if ((typeof contractQuota) === 'string') {
      const parsed = Number.parseInt(contractQuota as any);
      val = isNaN(parsed) ? null : parsed;
    }
    state.model.contractQuota = val;
  };
}

function setMonthlyQuota (state: IAddServiceState): TSetMonthlyQuota {
  return (monthlyQuota) => {
    let val = monthlyQuota;
    if ((typeof monthlyQuota) === 'string') {
      const parsed = Number.parseInt(monthlyQuota as any);
      val = isNaN(parsed) ? null : parsed;
    }
    state.model.monthlyQuota = val;
  };
}

function setDailyQuota (state: IAddServiceState): TSetDailyQuota {
  return (dailyQuota) => {
    let val = dailyQuota;
    if ((typeof dailyQuota) === 'string') {
      const parsed = Number.parseInt(dailyQuota as any);
      val = isNaN(parsed) ? null : parsed;
    }
    state.model.dailyQuota = val;
  };
}

function setServiceType (state: IAddServiceState): TSetServiceType {
  return (serviceType) => {
    state.model.serviceType = serviceType;
  };
}

function setFeaturesFlag (state: IAddServiceState): TSetFeaturesFlag {
  return (featuresFlag) => {
    state.model.featuresFlag = featuresFlag;
  };
}

function setTanglesApiKey (state: IAddServiceState): TSetTanglesApiKey {
  return (tanglesApiKey) => {
    state.model.tanglesApiKey = tanglesApiKey;
  };
}

function setCreateModel (state: IAddServiceState): TSetCreateModel {
  return (createModel) => {
    state.model.createModel = createModel;
  };
}

function setIndex (state: IAddServiceState): TSetIndex {
  return (index) => {
    state.model.index = index;
  };
}

function validate (state: IAddServiceState): TValidate {
  return async () => {
    return await validationStore.doValidation({
      model: validationModel,
      value: state.model
    });
  };
}

export function createState (): IAddServiceState {
  validationStore.clear(validationModel);

  return reactive(impl<IAddServiceState>({
    model: {
      contractStartDate: null,
      contractEndDate: null,
      contractQuota: Settings.defaultContractQuota,
      monthlyQuota: Settings.defaultMonthlyQuota,
      dailyQuota: Settings.defaultDailyQuota,
      serviceType: SubscriptionServiceType.None,
      featuresFlag: 1, // coincidentally want to default to Tag's for Vision and Tangles for Profile
      tanglesApiKey: null,
      createModel: null,
      index: null
    }
  }));
}

export function createForState (state: IAddServiceState): IAddServiceStore {
  return impl<IAddServiceStore>({
    state: readonly(state),
    setModel: setModel(state),
    setContractStartDate: setContractStartDate(state),
    setContractEndDate: setContractEndDate(state),
    setContractQuota: setContractQuota(state),
    setMonthlyQuota: setMonthlyQuota(state),
    setDailyQuota: setDailyQuota(state),
    setFeaturesFlag: setFeaturesFlag(state),
    setTanglesApiKey: setTanglesApiKey(state),
    setServiceType: setServiceType(state),
    setCreateModel: setCreateModel(state),
    setIndex: setIndex(state),
    validate: validate(state)
  });
}

export function provideStore (): IAddServiceStore {
  const state = createState();

  provide(AddServiceStoreKey, state);

  return createForState(state);
}

export function useStore (): IAddServiceStore {
  const state = inject<IAddServiceState>(AddServiceStoreKey);
  if (state === undefined) {
    throw new Error('Using AddServiceStore before providing it!');
  }
  return createForState(state);
}
