import { impl } from '@/utils/impl';
import { App, inject, provide, reactive, readonly } from 'vue';
import {
  ISubscriptionsState,
  TSetSubscriptions,
  TLoadSubscriptions,
  TEnableSubscription,
  TDisableSubscription,
  ISubscriptionsStore,
  TSetShowDisabled,
  TIsEnabled,
  TRefreshCachedSubscriptions
} from '@/store/contracts/subscriptions/subscriptions';
import {
  chainHandlers,
  handleErrorStatus,
  handleErrorStatusResult,
  IStatusHandler,
  ResponseStatus
} from '@/store/utils/apiResponseHandling';
import { sendRequest } from '@/store/utils/axiosUtils';
import { Settings } from '@/settings';
import {
  IServerSubscriptionListingResponse,
  subscriptionListingToApp
} from '@/store/subscriptions/mapping/subscriptionListingModel';
import { setEnd, setStart } from '@/utils/dateUtil';
import { SubscriptionUpdateType } from '../contracts/subscriptions/subscriptionUpdateType';

const SubscriptionsStoreKey = Symbol('SubscriptionsStore');

const createState = () => reactive(impl<ISubscriptionsState>({
  subscriptions: null,
  showDisabled: false
}));

function setSubscriptions (state: ISubscriptionsState): TSetSubscriptions {
  return (subscriptions) => {
    state.subscriptions = subscriptions;
  };
}

function loadSubscriptions (state: ISubscriptionsState): TLoadSubscriptions {
  return async (handler?: Partial<IStatusHandler<IServerSubscriptionListingResponse>>) => {
    await handleErrorStatusResult(await sendRequest<IServerSubscriptionListingResponse>(
      `${Settings.adminApiBaseUrl}/admin/v1/subscription/loadGrid`,
      'get'
    ), chainHandlers({
      onSuccess: async (s) => {
        setSubscriptions(state)(s.Subscriptions.map(subscriptionListingToApp));
      }
    }, handler));
  };
}

function setShowDisabled (state: ISubscriptionsState): TSetShowDisabled {
  return (showDisabled) => {
    state.showDisabled = showDisabled;
  };
}

const isEnabled: TIsEnabled = (subscription) => {
  const activeDate = subscription.activeDate;
  const expiresDate = subscription.expiresDate;

  const now = new Date();
  return (activeDate === null || activeDate <= now) && (expiresDate === null || now <= expiresDate);
};

function disableSubscription (state: ISubscriptionsState): TDisableSubscription {
  return async (subscriptionId, handler) => {
    const expires = new Date();
    expires.setUTCDate(expires.getUTCDate() - 1);
    setEnd(expires);
    const active = state.subscriptions?.find(s => s.subscriptionId === subscriptionId)?.activeDate ?? expires;
    setStart(active);

    await handleErrorStatus(
      await sendRequest<ResponseStatus.Success>(
        `${Settings.adminApiBaseUrl}/admin/v1/subscription/update`,
        'post',
        {
          $type: SubscriptionUpdateType.UpdateSubscriptionStatus,
          SubscriptionId: subscriptionId,
          ActiveDate: active,
          ExpiresDate: expires
        }
      ), chainHandlers({
        onSuccess: async () => loadSubscriptions(state)()
      }, handler));
  };
}

function enableSubscription (state: ISubscriptionsState): TEnableSubscription {
  return async (subscriptionId, handler) => {
    const currentDate = new Date();
    const active = currentDate;
    const expires = new Date(currentDate.getTime());
    // Setting expires date a year out since it is required on backend.
    expires.setFullYear(currentDate.getFullYear() + 1);
    setEnd(expires);
    setStart(active);
    await handleErrorStatus(
      await sendRequest<ResponseStatus.Success>(
        `${Settings.adminApiBaseUrl}/admin/v1/subscription/update`,
        'post',
        {
          $type: SubscriptionUpdateType.UpdateSubscriptionStatus,
          SubscriptionId: subscriptionId,
          ActiveDate: active,
          ExpiresDate: expires
        }
      ), chainHandlers({
        onSuccess: async () => loadSubscriptions(state)()
      }, handler));
  };
}

function refreshCachedSubscriptions (): TRefreshCachedSubscriptions {
  return async (handler) => {
    let success = false;
    await handleErrorStatusResult(await sendRequest<ResponseStatus.Success>(
      `${Settings.adminApiBaseUrl}/admin/v1/subscription/refreshCache`,
      'post'
    ), chainHandlers({
      onSuccess: async () => { success = true; },
      onError: async () => { success = false; }
    }, handler));
    return success;
  };
}

const storeState = createState();

const createForState = (state: ISubscriptionsState) => impl<ISubscriptionsStore>({
  state: readonly(state),
  setSubscriptions: setSubscriptions(state),
  loadSubscriptions: loadSubscriptions(state),
  setShowDisabled: setShowDisabled(state),
  isEnabled,
  enableSubscription: enableSubscription(state),
  disableSubscription: disableSubscription(state),
  refreshCachedSubscriptions: refreshCachedSubscriptions()
});

export const store: ISubscriptionsStore = createForState(storeState);

export function provideStore (app?: App<Element>): void {
  if (app !== undefined) {
    app.provide(SubscriptionsStoreKey, storeState);
  } else {
    provide(SubscriptionsStoreKey, storeState);
  }
}

export function useStore (): ISubscriptionsStore {
  const state = inject<ISubscriptionsState>(SubscriptionsStoreKey);
  if (state === undefined) {
    throw new Error('Using SubscriptionsStore before providing it!');
  }
  return createForState(state);
}
